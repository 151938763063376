import {
	breakpoints,
	LeftHexagonLaptop,
	LeftHexagonMobile,
	LeftHexagonTablet,
	RightHexagonLaptop,
	RightHexagonMobile,
	RightHexagonTablet,
	useHasScreenWidth,
} from '@mms/mms-ui-library';

const { desktopXS, tabletXS } = breakpoints;

export const useHexagonIcons = () => {
	const isDesktopSWidth = useHasScreenWidth(desktopXS);
	const isTabletWidth = useHasScreenWidth(tabletXS);

	if (isDesktopSWidth) {
		return [LeftHexagonLaptop, RightHexagonLaptop];
	}

	if (isTabletWidth) {
		return [LeftHexagonTablet, RightHexagonTablet];
	}

	return [LeftHexagonMobile, RightHexagonMobile];
};
