import { Routes } from '@/constants/index';

export const HEADER_HEIGHT = 54;

export const bothScrollLayoutRoutes = [
	Routes.dashboard,
	Routes.reports,
	Routes.updateProfile,
	Routes.booking,
	Routes.timeSheet,
];

export const BURGER_ICON_HOVER_CIRCLE_SIZE = 32;
export const HOVER_CIRCLE_TRANSITION_DURATION_IN_MS = 200;
export const SCROLL_BOTTOM_MARGIN = 16;
