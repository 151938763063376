const API_BASE_PREFIX = '/api';
export const GET_CLIENTS_API_ENDPOINT = `${API_BASE_PREFIX}/Clients`;
export const GET_PROJECTS_API_ENDPOINT = `${API_BASE_PREFIX}/Projects`;
export const GET_DEPARTMENTS_API_ENDPOINT = `${API_BASE_PREFIX}/Departments`;
export const GET_USERS_API_ENDPOINT = `${API_BASE_PREFIX}/Users`;
export const PERFORMANCE_REVIEW_API_ENDPOINT = `${API_BASE_PREFIX}/performance-review`;
export const USER_PERFORMANCE_REVIEW_API_ENDPOINT = `${API_BASE_PREFIX}/performance-review/user`;
export const GET_ACCOUNT_API_ENDPOINT = `${API_BASE_PREFIX}/account`;
export const MICROSOFT_LOGIN_REDIRECT = `${API_BASE_PREFIX}/account/login`;
export const REFRESH_JWT_TOKEN = `${API_BASE_PREFIX}/account/refresh-token`;
export const LOGOUT_URL = `${API_BASE_PREFIX}/account/logout`;
export const GET_TIMESHEET_API_ENDPOINT = `${API_BASE_PREFIX}/Timesheet`;
export const GET_DASHBOARD_API_ENDPOINT = `${API_BASE_PREFIX}/Dashboard`;
export const GET_DASHBOARD_BAR_CHART_API_ENDPOINT = `${GET_DASHBOARD_API_ENDPOINT}/tracked-time`;
export const UPDATE_CURRENT_USER = `${API_BASE_PREFIX}/users/current`;
export const GET_CONFIGURATION = `${API_BASE_PREFIX}/Configuration`;
export const GET_REPORTS = `${API_BASE_PREFIX}/Reports`;
export const GET_CLIENTS_LOOKUP = `${GET_CLIENTS_API_ENDPOINT}/lookup`;
export const GET_PROJECTS_LOOKUP = `${GET_PROJECTS_API_ENDPOINT}/lookup`;
export const GET_PROJECT_MANAGERS_API_ENDPOINT = `${GET_PROJECTS_API_ENDPOINT}/managers`;
export const GET_REPORTS_EXCEL = `${GET_REPORTS}/excel`;
export const GET_REPORTS_WORD = `${GET_REPORTS}/word`;
export const GET_REPORTS_PDF = `${GET_REPORTS}/pdf`;
export const GET_CERTIFICATES_API_ENDPOINT = `${API_BASE_PREFIX}/certificate`;
export const GET_NOTIFICATIONS = `${API_BASE_PREFIX}/Notifications`;
export const GET_RESERVATION_API_ENDPOINT = `${API_BASE_PREFIX}/Reservation`;
export const GET_BOOKING_API_ENDPOINT = `${API_BASE_PREFIX}/Booking`;
export const getWorkplacesByOfficeIdAPIEndpoint = (officeId: number) =>
	`${API_BASE_PREFIX}/offices/${officeId}/workplaces`;
export const getMeetingRoomsBookingsAPIEndpoint = (officeId: number) =>
	`${API_BASE_PREFIX}/offices/${officeId}/meeting-room-entries`;
export const getMeetingRoomsReservationsAPIEndpoint = (
	officeId: number,
	date: string
) =>
	`${API_BASE_PREFIX}/offices/${officeId}/calendar/${date}/meeting-room-reservations`;
const BOOKING_MEETING_ROOMS_PREFIX = `${API_BASE_PREFIX}/meeting-rooms`;
export const createMeetingRoomDeleteBookingApiUrl = (roomBookingId: number) =>
	`${API_BASE_PREFIX}/meeting-rooms-entries/${roomBookingId}`;
export const createMeetingRoomBooking = (roomId: number) =>
	`${BOOKING_MEETING_ROOMS_PREFIX}/${roomId}/meeting-room-reservations`;
export const MEETING_ROOMS_PREFIX = `${API_BASE_PREFIX}/offices/meeting-rooms`;
export const GET_COLLISIONS_API_ENDPOINT = `${API_BASE_PREFIX}/Collisions`;
export const getMeetingRoomsCollisionsApiUrl = (roomId: number) =>
	`${API_BASE_PREFIX}/meeting-rooms/${roomId}/collisions`;
export const GET_CLIENT_PROJECTS = `${GET_CLIENTS_API_ENDPOINT}/projects`;
export const GET_FILE = `${API_BASE_PREFIX}/ApplicationFile`;
export const GET_EQUIPMENT_API_ENDPOINT = `${API_BASE_PREFIX}/Equipment`;
export const GET_EQUIPMENT_USERS_LOOKUP_ENDPOINT = `${API_BASE_PREFIX}/equipment-users/lookup`;
export const GET_EQUIPMENT_HISTORY_API_ENDPOINT = `${API_BASE_PREFIX}/EquipmentHistory`;
export const GET_SIGNATURE = `${GET_USERS_API_ENDPOINT}/current/signature`;
export const GET_EQUIPMENT_FILE = `${GET_EQUIPMENT_API_ENDPOINT}/file`;
export const GET_PERMISSIONS_API_ENDPOINT = `${API_BASE_PREFIX}/permissions`;
export const GET_PERMISSIONS_ASSIGNMENTS_API_ENDPOINT = `${GET_PERMISSIONS_API_ENDPOINT}/assignments`;
export const GET_PERMISSIONS_ASSIGNMENTS_RESOURCES_API_ENDPOINT = `${GET_PERMISSIONS_ASSIGNMENTS_API_ENDPOINT}/resources`;
export const GET_USER_CONTRACTS_API_ENDPOINT = `${API_BASE_PREFIX}/contracts`;
export const GET_USER_PROJECTS_ENDPOINT = `${API_BASE_PREFIX}/user-projects`;
export const getUserPrivateProjectsEndpointUrl = (userId: number) =>
	`${API_BASE_PREFIX}/users/${userId}/projects`;
export const getClientProjectsEndpointUrl = (clientId: number) =>
	`${GET_CLIENTS_API_ENDPOINT}/${clientId}/projects`;
export const GET_HARD_SKILLS_API_ENDPOINT = `${API_BASE_PREFIX}/hard-skills`;
export const getUserNotificationPrefixEndpointUrl = (userId: number) =>
	`${API_BASE_PREFIX}/users/${userId}/notifications`;
export const getUserNotificationsEndpointUrl = (userId: number) =>
	`${getUserNotificationPrefixEndpointUrl(userId)}/search`;
export const getUserNotificationsPreviewEndpointUrl = (userId: number) =>
	`${getUserNotificationPrefixEndpointUrl(userId)}/unread/preview`;
export const getReportFileEndpointUrl = (fileId: string) =>
	`${API_BASE_PREFIX}/applicationfile/${fileId}`;
export const getUserTimesheetStatusByMonthEndpointUrl = (userId: number) =>
	`${API_BASE_PREFIX}/users/${userId}/timesheets/status`;

export const GET_SOFT_SKILLS_API_ENDPOINT = `${API_BASE_PREFIX}/soft-skills`;
export const GET_SPECIALIZATION_API_ENDPOINT = `${API_BASE_PREFIX}/specialization-templates`;
export const GET_TEAMBOARD_API_ENDPOINT = `${API_BASE_PREFIX}/teamboard-users`;
export const GET_PERSONAL_DATA_API_ENDPOINT = `${API_BASE_PREFIX}/personal-info`;
export const GET_USER_COMMENT_API_ENDPOINT = `${GET_PERSONAL_DATA_API_ENDPOINT}/comment`;
export const GET_EMPLOYMENT_HISTORY_API_ENDPOINT = `${API_BASE_PREFIX}/employment-history`;
export const GET_USER_SPECIALIZATIONS_ENDPOINT = `${API_BASE_PREFIX}/user-specialization`;
export const GET_USER_CONVERSATIONS_ENDPOINT = `${API_BASE_PREFIX}/conversations`;
export const GET_USER_DEVELOPMENT_PLANS_ENDPOINT = `${API_BASE_PREFIX}/development-plans`;
export const GET_USER_DEVELOPMENT_PLANS_LIST_ENDPOINT = `${GET_USER_DEVELOPMENT_PLANS_ENDPOINT}/development-plans`;
export const GET_USER_FEEDBACKS_ENDPOINT = `${API_BASE_PREFIX}/external-feedbacks`;
export const GET_USER_FEEDBACKS_CLIENTS_ENDPOINT = `${GET_USER_FEEDBACKS_ENDPOINT}/clients`;
export const GET_USER_FEEDBACKS_REVIEWERS_ENDPOINT = `${GET_USER_FEEDBACKS_ENDPOINT}/reviewers`;
export const GET_USER_SUPERVISORS_ENDPOINT = `${GET_USER_CONVERSATIONS_ENDPOINT}/supervisors`;
export const getDevelopmentPlanEndpointUrl = (developmentPlanId: number) =>
	`${GET_USER_DEVELOPMENT_PLANS_ENDPOINT}/${developmentPlanId}`;
export const getFeedbackEndpointUrl = (feedbackId: number) =>
	`${GET_USER_FEEDBACKS_ENDPOINT}/${feedbackId}`;
export const GET_USER_SALARY_ENDPOINT = `${API_BASE_PREFIX}/user-salary`;

export const getUserProjectEndpointUrl = (userId: number, projectId: number) =>
	`${API_BASE_PREFIX}/users/${userId}/projects/${projectId}`;

export const GET_USER_SOFT_SKILLS_ENDPOINT = (
	userId: number | string | undefined
) => `${API_BASE_PREFIX}/users/${userId}/soft-skills`;

export const UPDATE_USER_SOFT_SKILLS_ENDPOINT = (
	userId: number | string | undefined,
	softSkillId: string
) => `${API_BASE_PREFIX}/users/${userId}/soft-skills/${softSkillId}`;
export const GET_USER_SALARIES_ENDPOINT = `${GET_USER_SALARY_ENDPOINT}/salaries`;
export const getUniqueUserSpecializationsEndpointUrl = (userId: number) =>
	`${API_BASE_PREFIX}/users/${userId}/specialization-templates`;

export const ADMIN_PANEL_WORKING_HOURS_API_ENDPOINT = `${API_BASE_PREFIX}/working-hours-per-months`;
export const ADMIN_PANEL_COUNTRIES_API_ENDPOINT = `${ADMIN_PANEL_WORKING_HOURS_API_ENDPOINT}/countries`;

export const getRedirectInfoBuIdEndpointUrl = (id: string) =>
	`${API_BASE_PREFIX}/redirect-rules/${id}`;

export const createUserProjectAssignmentEnpointUrl = (
	userId: number,
	projectId: number
) => `${API_BASE_PREFIX}/users/${userId}/projects/${projectId}`;
