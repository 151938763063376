export interface FullName {
	firstName: string;
	lastName: string;
}

export const getFullName = (firstName: string, lastName: string) =>
	`${lastName} ${firstName}`;

export const formatFullName = (lastName: string, firstName: string) =>
	getFullName(firstName || '', lastName || '-');

export const splitFullName = (fullName: string) => {
	const [firstName = '', lastName = ''] = fullName.split(' ');

	return {
		firstName,
		lastName,
	};
};

export const parseFullName = <T extends FullName>(
	fullName: string | T
): FullName => {
	if (typeof fullName === 'string') {
		return splitFullName(fullName);
	}

	const { firstName, lastName } = fullName;

	return {
		firstName,
		lastName,
	};
};
