import { FromToProps } from '@/components/Grid/modals/types';
import { Configuration } from '@/types/Configuration';

export const fromToDateObject: FromToProps<Date> = {
	from: null,
	to: null,
};

export const fromToCurrencyObject: FromToProps<string> = {
	from: null,
	to: null,
};

export const defaultConfiguration: Configuration = {
	persistentValues: {
		equipmentAvailabilityTypes: [],
		equipmentLocationTypes: [],
		jsStackTypes: [],
		locationTypes: [],
		moneyCurrencyTypes: [],
		permissionResourceTypes: [],
		permissionTypes: [],
		roleTypes: [],
		scheduleTypes: [],
		equipmentBrokenStatuses: [],
	},
	configurableValues: {
		departmentTypes: [],
		englishLevels: [],
		equipmentTypes: [],
		hardSkillGroups: [],
		offices: [],
		seniorityLevels: [],
		softSkillSeniorityLevels: [],
		countries: [],
		generalSenioritySeniorityLevels: [],
	},
	features: [],
};
