import moment, { Moment } from 'moment';

import { SelectedBookingItemType } from '@/context/types';
import { isMeetingRoom } from '@/helpers/isMeetingRoom';
import {
	GetBookingResponse,
	GetRoomsBookingResponse,
} from '@/queries/booking/types';
import { DeskBooking, MeetingRoomBooking } from '@/types/Booking';

import {
	ConvertedDeleteDeskBookingItem,
	ConvertedDeleteMeetingRoomBookingItem,
	DeskBookingItems,
	RoomBookingItems,
} from './types';

export const getRepetitiveWeekDays = (
	momentDateList: Moment[],
	weeksInInterval: number
) => {
	const dateFrom = momentDateList[0];
	const dateTo = momentDateList[1];

	const repetitiveWeekDays: Moment[] = [];

	const middleDate = moment(dateFrom);

	while (middleDate.isSameOrBefore(dateTo)) {
		repetitiveWeekDays.push(middleDate.clone());
		middleDate.add(weeksInInterval, 'week');
	}

	return repetitiveWeekDays;
};

export const getDateRange = (dateFrom: Moment, dateTo: Moment): Moment[] => {
	const dates: Moment[] = [];

	const currentDate = dateFrom.clone();

	while (currentDate.isSameOrBefore(dateTo, 'day')) {
		dates.push(currentDate.clone());
		currentDate.add(1, 'day');
	}

	return dates;
};

export const getOrderedBookings = (
	deskBookingData: GetBookingResponse,
	roomsBookingData: GetRoomsBookingResponse,
	areMeetingRoomsOpen: boolean
): Array<RoomBookingItems | DeskBookingItems> => {
	if (areMeetingRoomsOpen) {
		return [
			{
				type: SelectedBookingItemType.Room,
				items: roomsBookingData,
			},
			{
				type: SelectedBookingItemType.Desk,
				items: deskBookingData.bookings,
			},
		];
	}

	return [
		{
			type: SelectedBookingItemType.Desk,
			items: deskBookingData.bookings,
		},
		{
			type: SelectedBookingItemType.Room,
			items: roomsBookingData,
		},
	];
};

export const convertBookingItemToPopupData = (
	item: MeetingRoomBooking | DeskBooking | null
):
	| null
	| ConvertedDeleteDeskBookingItem
	| ConvertedDeleteMeetingRoomBookingItem => {
	if (!item) {
		return null;
	}

	if (isMeetingRoom(item)) {
		return {
			type: SelectedBookingItemType.Room,
			isBlock: false,
			...item,
		};
	}

	return {
		type: SelectedBookingItemType.Desk,
		...item,
	};
};
