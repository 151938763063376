import styled from '@emotion/styled';
import { Typography } from '@mms/mms-ui-library';

import { Avatar } from '@/components/Avatar';

const AVATAR_SIZE = 90;
const DROPDOWN_WRAPPER_WIDTH = 266;
const HEADER_HEIGHT = 54;
const AVATAR_HEIGHT = 40;

export const DropdownWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	width: DROPDOWN_WRAPPER_WIDTH,
});

export const DropdownHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	paddingTop: theme.spacing(1),
	height: HEADER_HEIGHT,
}));

export const CustomAvatar = styled(Avatar)(({ theme }) => ({
	...theme.typography.headlines.small,
	marginTop: theme.spacing(3),
	width: AVATAR_SIZE,
	height: AVATAR_SIZE,
	'& img': {
		width: AVATAR_SIZE,
		height: AVATAR_SIZE,
	},
}));

export const ContentWrapper = styled('div')(({ theme }) => ({
	padding: theme.spacing(0, 3, 1),
}));

export const DropdownFooter = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-end',
	margin: theme.spacing(2, 0, 0.5, 0),
}));

export const Email = styled(Typography)(({ theme }) => ({
	marginTop: theme.spacing(1),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	color: (theme.palette as any)['base-5'],
}));

export const TimeZoneTypography = styled(Typography)(({ theme }) => ({
	marginTop: theme.spacing(2),
}));

export const Position = styled(Typography)(({ theme }) => ({
	marginTop: theme.spacing(1),
	color: (theme.palette as any)['accent-6'],
}));

export const Surname = styled(Typography)(({ theme }) => ({
	marginTop: theme.spacing(7),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	color: (theme.palette as any)['base-6'],
}));

export const HeaderWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	height: HEADER_HEIGHT,
	background: theme.palette['exc-primaryHeader-primary-10'],
	padding: theme.spacing(2),
}));

export const AvatarWrapper = styled('div')({
	height: AVATAR_HEIGHT,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

export const TimeZoneWrapper = styled('div')`
	display: flex;
	justify-content: center;
`;

export const TimeZoneLocaleWrapper = styled('span')(({ theme }) => ({
	marginLeft: theme.spaces.s,
}));

export const CustomLink = styled('a')`
	color: inherit;
	text-decoration: inherit;
`;
