import { Theme } from '@emotion/react';

import { ThemeMode } from '@/theme';

const DEFAULT_PHOTO_COLOR = '#BDBDBD';

export const getBackgroundColor = (theme: Theme, bgColor?: string) => {
	const isDefaultColor = !bgColor || bgColor === DEFAULT_PHOTO_COLOR;

	if (isDefaultColor && theme.mode === ThemeMode.DARK) {
		return theme.palette['primary-10'];
	}

	if (isDefaultColor && ThemeMode.LIGHT === theme.mode) {
		return theme.palette['primary-4'];
	}

	return bgColor;
};
