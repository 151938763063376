import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const NoNotificationsPlaceholderWrapper = styled('div')(
	({ theme }) => css`
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: ${theme.spaces.m}px;
	`
);

export const BellIconWrapper = styled('div')(
	({ theme }) => css`
		color: ${theme.palette['primary-4']};
	`
);
