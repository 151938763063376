import moment, { Moment } from 'moment';

import { Timezone } from '@/components/Timeline/types';
import {
	DEFAULT_MINSK_SPACE_ABBREVIATION,
	MINSK_SPACE_ABBREVIATIONS,
	RESPONSE_TIME_FORMAT,
	WARSAW_SPACE_ABBREVIATION,
} from '@/constants/booking';
import { YYYY_MM_DD_MOMENT_DATE_FORMAT } from '@/constants/date';
import {
	DESK_UNBLOCKED,
	SUCCESS_BOOKING_CANCELLED,
	BOOKING_CANCELED,
} from '@/constants/notifications';
import { HighlightedDays } from '@/context/types';
import { parseMomentToUtcString } from '@/helpers/bookingParsers';
import {
	convertDeskDateListToMoment,
	mergeDateAndTime,
} from '@/helpers/bookingTime';
import { isMeetingRoom } from '@/helpers/isMeetingRoom';
import {
	GetBookingResponse,
	GetRoomsBookingResponse,
} from '@/queries/booking/types';
import { DeskBooking, MeetingRoomBooking } from '@/types/Booking';

import {
	getDateRange,
	getRepetitiveWeekDays,
} from './components/BookingList/utils';
import {
	BOOKING_DURATION_IN_MONTH,
	MEETING_ROOMS_TAB_ID,
} from './components/BookingWorkplace/constants';
import { BookingOfficesId } from './types';

const convertOfficeDateToISOSFormat = (officeDate: string) =>
	`${officeDate}T00:00:00.000Z`;

export const getMeetingRoomDateList = (
	reservationPeriodFromOfficeDate: string,
	reservationPeriodToOfficeDate: string,
	isRepetitiveBooking: boolean
) => {
	if (isRepetitiveBooking) {
		const fromDate = moment(
			convertOfficeDateToISOSFormat(reservationPeriodFromOfficeDate)
		).utc(false);

		return [
			fromDate.toISOString(),
			fromDate.clone().add(BOOKING_DURATION_IN_MONTH, 'months').toISOString(),
		];
	}

	if (reservationPeriodFromOfficeDate === reservationPeriodToOfficeDate) {
		return [convertOfficeDateToISOSFormat(reservationPeriodToOfficeDate)];
	}

	return [
		convertOfficeDateToISOSFormat(reservationPeriodFromOfficeDate),
		convertOfficeDateToISOSFormat(reservationPeriodToOfficeDate),
	];
};

export const getBlockedMonthDays = () =>
	new Array(31).fill(0).map((_, index) => index + 1);

const getParsedBookingItem = (item: DeskBooking | MeetingRoomBooking) => {
	if (isMeetingRoom(item)) {
		const {
			reservationPeriodFromOfficeDate,
			reservationPeriodToOfficeDate,
			weeksInInterval,
			userId,
			isRepetitiveBooking,
		} = item;

		return {
			dateList: getMeetingRoomDateList(
				reservationPeriodFromOfficeDate,
				reservationPeriodToOfficeDate,
				isRepetitiveBooking
			),
			weeksInInterval,
			isBlock: false,
			userId,
		};
	}

	return item;
};

export const extractHighlightedDaysFromResponse = (
	desksData: GetBookingResponse,
	roomsData: GetRoomsBookingResponse,
	id: number,
	timezone: Timezone
) => {
	const deskAndMeetingRoomsBooking = [
		...desksData.bookings,
		...roomsData.map(getParsedBookingItem),
	];
	const days = deskAndMeetingRoomsBooking.reduce(
		(acc: HighlightedDays, item) => {
			const { dateList, isBlock, userId, weeksInInterval } = item;
			const momentDateList = convertDeskDateListToMoment(dateList, timezone);

			if (userId === id) {
				if (weeksInInterval > 0) {
					const repetitiveWeekDays = getRepetitiveWeekDays(
						momentDateList,
						weeksInInterval
					);
					acc.yourList?.push(...repetitiveWeekDays);

					return acc;
				}

				if (momentDateList.length === 1) {
					acc.yourList?.push(...momentDateList);

					return acc;
				}

				const [rangeStart, rangeEnd] = momentDateList;

				if (isBlock) {
					acc.blockStartDate = rangeStart;
				} else {
					const dateRange = getDateRange(rangeStart, rangeEnd);

					acc.yourList?.push(...dateRange);
				}

				return acc;
			}

			acc.bookedList?.push(...momentDateList);

			return acc;
		},
		{
			bookedList: [],
			blockStartDate: null,
			yourList: [],
		}
	);

	return days;
};

export const getDeskName = (abbreviation: string, deskNumber: number) =>
	`${abbreviation}-${deskNumber}`;

export const areMeetingRoomsOpen = (tabId: number | null) =>
	tabId === MEETING_ROOMS_TAB_ID;

const isZeroTime = (time: Moment) => time.hour() === 0 && time.minute() === 0;

const getConvertedBookingTime = (
	time: Moment,
	isAllDayChecked: boolean,
	zeroTimeAlternative: string
): Moment =>
	isAllDayChecked || isZeroTime(time)
		? moment(zeroTimeAlternative, RESPONSE_TIME_FORMAT)
		: time;

export const getRequestBookingDateTime = (
	date: Moment,
	time: Moment,
	isAllDayChecked: boolean,
	zeroTimeAlternative: string,
	timezone: Timezone
) => {
	const convertedTime = getConvertedBookingTime(
		time,
		isAllDayChecked,
		zeroTimeAlternative
	);

	return parseMomentToUtcString(
		mergeDateAndTime(date, convertedTime),
		timezone
	);
};

export const getRequestBookingDate = (date: Moment) =>
	date.format(YYYY_MM_DD_MOMENT_DATE_FORMAT);

export const getBookingDeletingToastMessage = (
	isBlock: boolean,
	isOwnBooking: boolean
) => {
	if (isBlock) {
		return DESK_UNBLOCKED;
	}

	if (isOwnBooking) {
		return SUCCESS_BOOKING_CANCELLED;
	}

	return BOOKING_CANCELED;
};

export const getSpaceAbbreviation = (
	officeId: number,
	spaceId: number | null
) => {
	if (officeId === BookingOfficesId.Minsk) {
		return spaceId
			? MINSK_SPACE_ABBREVIATIONS[spaceId] || DEFAULT_MINSK_SPACE_ABBREVIATION
			: DEFAULT_MINSK_SPACE_ABBREVIATION;
	}

	if (officeId === BookingOfficesId.Warsaw) {
		return WARSAW_SPACE_ABBREVIATION;
	}

	return '';
};
