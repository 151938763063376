// eslint-disable-next-line import/no-extraneous-dependencies
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Popover } from '@mms/mms-ui-library';
import { useCallback, useEffect, useRef, useState } from 'react';

import { API_BASE_URL } from '@/constants/apiBaseUrl';
import { LOCATION } from '@/constants/location';
import { useAuth } from '@/context/AuthContext';
import { useOutsideClickMany } from '@/hooks/useOutsideClickMany';
import { useGetUserNotificationsPreview } from '@/queries/notifications/useGetNotificationsPreview';

import {
	HEADER_INCLUDED_NOTIFCATION_TYPES,
	POPPER_BOTTOM_OFFSET,
	popperOffset,
	popperStyles,
} from './constants';
import { NotificationsButton } from './NotificationButton';
import { NotificationList } from './NotificationsList';
import { notificationsHubEvents } from './NotificationsList/constants';

export function Notifications() {
	const { id: userId } = useAuth();
	const [notificationsAvailableHeight, setNotificationsAvailableHeight] =
		useState<number>(0);
	const [isOpen, setIsOpen] = useState(false);
	const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
	const butonRef = useRef<HTMLButtonElement | null>(null);
	const [connection, setConnection] = useState<null | HubConnection>(null);

	const { token } = useAuth();

	useEffect(() => {
		if (connection && !isOpen) {
			const handleRecieveNotification = () => {
				setHasUnreadNotifications(true);
			};

			connection.on(
				notificationsHubEvents.receiveNotification,
				handleRecieveNotification
			);

			return () => {
				connection.off(
					notificationsHubEvents.receiveNotification,
					handleRecieveNotification
				);
			};
		}
	}, [isOpen, connection]);

	useEffect(() => {
		if (token) {
			const origin = window.location.origin as LOCATION;
			const newConnection = new HubConnectionBuilder()
				.withUrl(`${API_BASE_URL[origin]}/hubs/notifications`, {
					withCredentials: false,
					accessTokenFactory: () => token,
				})
				.withAutomaticReconnect()
				.build();

			newConnection.start();

			setConnection(newConnection);

			return () => {
				if (newConnection.connectionId) {
					newConnection.stop();
					setConnection(null);
				}
			};
		}
	}, [token]);

	const { data, refetch, dataUpdatedAt } = useGetUserNotificationsPreview(
		userId,
		HEADER_INCLUDED_NOTIFCATION_TYPES
	);

	useEffect(() => {
		if (data !== undefined) {
			setHasUnreadNotifications(data);
		}
	}, [data, dataUpdatedAt]);

	const handleNotificationsButtonClick = useCallback(() => {
		setIsOpen((prevIsOpen) => !prevIsOpen);
	}, []);

	const handleClickOutside = useCallback(() => {
		setIsOpen(false);
	}, []);

	useOutsideClickMany([butonRef], handleClickOutside);

	const refetchHasUnreadNotifications = useCallback(() => {
		refetch();
	}, []);

	const setNotificationsMaxHeight = useCallback((availableHeight: number) => {
		setNotificationsAvailableHeight(availableHeight - POPPER_BOTTOM_OFFSET);
	}, []);

	return (
		<>
			<NotificationsButton
				showBadge={hasUnreadNotifications}
				ref={butonRef}
				onClick={handleNotificationsButtonClick}
			/>
			{isOpen && (
				<Popover
					open={isOpen}
					anchorElement={butonRef.current}
					position="bottom-end"
					offset={popperOffset}
					preventOverflow={false}
					popperStyles={popperStyles}
					availableHeight
					setAvailableHeight={setNotificationsMaxHeight}
				>
					<NotificationList
						maxHeight={notificationsAvailableHeight}
						connection={connection}
						refetchUnreadNotificationStatus={refetchHasUnreadNotifications}
						hasUnreadNotifications={hasUnreadNotifications}
					/>
				</Popover>
			)}
		</>
	);
}
