import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { getScrollbarStyles, Typography } from '@mms/mms-ui-library';

import { TabableContainer } from '@/components/TabableContainer';

import { NOTIFICATION_LIST_PARENT_CLASS } from './constants';

export const NotificationListWrapper = styled('div')<{ maxHeight: number }>(
	({ theme, maxHeight }) => css`
		max-height: ${maxHeight}px;
		display: flex;
		flex-direction: column;
		width: 100%;
		background: ${theme.palette['primary-1']};
		border-radius: 6px;
		border: 1px solid ${theme.palette['primary-4']};
		box-shadow: ${theme.mode === 'light'
			? '0px 2px 30px 0px #71799B33'
			: '0px 2px 47px rgba(44, 47, 58, 0.80)'};
	`
);

export const NotificationsListHeaderSpaceContainer = styled('div')(
	({ theme }) => css`
		padding: ${theme.spaces.m}px;
		padding-bottom: 0;
	`
);
export const NotificationsListHeaderWrapper = styled('div')(
	({ theme }) => css`
		display: flex;
		justify-content: space-between;
		padding-bottom: ${theme.spaces.m}px;
		border-bottom: 1px solid ${theme.palette['primary-4']};
	`
);

export const NotificationsListBodySpaceContainer = styled('div')(
	({ theme }) => css`
		padding: 0 ${theme.spaces.m}px;
		display: flex;
		flex-direction: column;
		gap: ${theme.spaces.m}px;
	`
);

export const ClearAllButton = styled(TabableContainer)(
	({ theme }) => css`
		color: ${theme.palette['accent-6']};
		cursor: pointer;
	`
);

export const ScrollbarWrapper = styled('div')(({ theme }) => ({
	...getScrollbarStyles(theme, 3, 'vertical'),
	margin: `${theme.spaces.m}px 0`,
	'&::-webkit-scrollbar-track': {
		backgroundColor: 'transparent',
	},

	[`&>.${NOTIFICATION_LIST_PARENT_CLASS}>div:last-child`]: {
		borderBottomWidth: 0,
	},
}));

export const LoaderContainer = styled('div')`
	display: flex;
	width: 100%;
	justify-content: center;
`;

export const AllClearDialogWrapper = styled('div')``;

export const HeaderTypography = styled(Typography)(
	({ theme }) => css`
		color: ${theme.palette['base-6']};
	`
);
