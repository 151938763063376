import { Bell, Typography } from '@mms/mms-ui-library';

import { NO_NOTIFICATIONS_PLACEHOLDER } from '@/constants/staticText';

import { BellIconWrapper, NoNotificationsPlaceholderWrapper } from './styles';

export function NoNotificationsPlaceholder() {
	return (
		<NoNotificationsPlaceholderWrapper>
			<BellIconWrapper>
				<Bell />
			</BellIconWrapper>
			<Typography variant="m-400">{NO_NOTIFICATIONS_PLACEHOLDER}</Typography>
		</NoNotificationsPlaceholderWrapper>
	);
}
