export const DEFAULT_ICON_SIZE = 24;
export const SMALL_ICON_SIZE = 20;
export const BOX_RANDOM_COLOR_SIZE = 20;
export const DEFAULT_CONTROL_HEIGHT = 36;
export const MOBILE_CONTROL_HEIGHT = 40;

export const DEFAULT_TAG_HEIGHT = 26;
export const DATA_GRID_ROW_HEIGHT_WITH_TAG = 34;

export const PAGE_HEADER_CONTROLS_HEIGHT = DEFAULT_CONTROL_HEIGHT;

export const MOBILE_PAGE_HEADER_CONTROLS_HEIGHT = 40;

export const PLAIN_TEXT_FONT_SIZE = 14;

export const PAGE_HEADER_CONTROL_ICON_SIZE = {
	height: PAGE_HEADER_CONTROLS_HEIGHT,
	width: PAGE_HEADER_CONTROLS_HEIGHT,
};

export const MOBILE_PAGE_HEADER_CONTROL_ICON_SIZE = {
	height: MOBILE_PAGE_HEADER_CONTROLS_HEIGHT,
	width: MOBILE_PAGE_HEADER_CONTROLS_HEIGHT,
};

export const DEFAULT_TRANSITION_DURATION = 0.2;

export const SCROLL_BAR_WIDTH = 6;
