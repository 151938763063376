import axios, {
	AxiosError,
	AxiosResponse,
	InternalAxiosRequestConfig,
} from 'axios';
import { useLayoutEffect } from 'react';

import { API_BASE_URL, LOCATION } from '@/constants/index';

const origin = window.location.origin as LOCATION;

axios.defaults.baseURL = API_BASE_URL[origin];

export const useAxiosInterCeptors = (
	token: string | null,
	resetAuthData: () => void
) => {
	useLayoutEffect(() => {
		const interceptors = {
			request: (config: InternalAxiosRequestConfig) => {
				const newConfig = { ...config };

				if (!token) {
					resetAuthData();
				}
				newConfig.headers.Authorization = `Bearer ${token}`;

				return newConfig;
			},
			error: (error: AxiosError) => {
				if (error?.response?.status === 401 && token) {
					resetAuthData();
				}

				return Promise.reject(error);
			},
			response: (response: AxiosResponse) => response,
		};

		const { request, response } = axios.interceptors;
		const reqInterceptor = request.use(interceptors.request, (error) =>
			Promise.reject(error)
		);

		const resInterceptor = response.use((res) => res, interceptors.error);

		return () => {
			request.eject(reqInterceptor);
			response.eject(resInterceptor);
		};
	}, [token]);
};
