import { Timezone } from '@/components/Timeline/types';
import { Employee } from '@/context/types';
import { MinskSpacesInternalCodes } from '@/pages/Booking/types';
import { LocationTypeId, OfficeType } from '@/types/Configuration';

export const COLOR_MAP = {
	available: 'base-2',
	blocked: 'feedback-info',
	yourBlocked: 'accent-6',
	booked: 'feedback-success',
	selected: 'accent-6',
	meetingRoom: 'primary-5',
};

export const DEFAULT_BOOKING_OFFICE: OfficeType = {
	id: 1,
	name: 'Minsk',
	location: LocationTypeId.Minsk,
	timeZone: Timezone.MINSK,
	timeZoneDisplayName: LocationTypeId.Minsk,
	meetingRooms: [
		{
			id: 1,
			name: 'Meeting Room 1',
		},
	],
	workspaces: [
		{
			id: 1,
			name: 'Space A',
			internalCode: MinskSpacesInternalCodes.SpaceA,
		},
		{
			id: 2,
			name: 'Space B',
			internalCode: MinskSpacesInternalCodes.SpaceB,
		},
		{
			id: 3,
			name: 'Space C',
			internalCode: MinskSpacesInternalCodes.SpaceC,
		},
		{
			id: 4,
			name: 'Space D',
			internalCode: MinskSpacesInternalCodes.SpaceD,
		},
	],
};

export const DEFAULT_EMPLOYEE: Employee = {
	id: 0,
	name: 'Modsen Company',
	positionDescription: 'Employee',
};

export const bookingTabs = [
	{
		id: 0,
		text: 'Bookings',
	},
	{
		id: 1,
		text: 'Spaces',
	},
];

export const bookingBlockTabs = [
	{
		id: 0,
		text: 'Details',
	},
	{
		id: 1,
		text: 'Bookings',
	},
];
export const DEFAULT_BOOKING_BLOCK_TAB_ID = 0;

export const bookingInitialTabIndex = bookingTabs[0].id;
export const BOOKING_BLOCK_HEADER_HEIGHT = 40;
export const BOOKING_TAB_INDEX_STORAGE_KEY = 'booking-tab-index';
export const BOOKING_DESKTOP_CHANGE_SCREEN_WIDTH = 2560;
export const BOOKING_LAPTOP_CHANGE_SCREEN_WIDTH = 1920;
export const BOOKING_TABS_CHANGE_SCREEN_WIDTH = 820;
export const BOOKING_MOBILE_SCREEN_WIDTH = 780;
export const BOOKING_SMALL_MOBILE_SCREEN_WIDTH = 450;
export const BOOKING_DESK_WIDTH = 39;
export const BOOKING_DESK_HEIGHT = 60;
export const BOOKING_DESK_SEAT_WIDTH = 6;
export const BOOKING_DESK_SEAT_HEIGHT = 18;

export const WORKPLACE_ELEMENT_ID = 'workplace';

export const BOOKING_DEFAULT_ICON_SIZE = 21;
