export enum PageTitles {
	Dashboard = 'Dashboard',
	Timesheet = 'Timesheet',
	Reports = 'Reports',
	Booking = 'Desk booking',
	Departments = 'Departments',
	Clients = 'Clients',
	Projects = 'Projects',
	Users = 'Users',
	Equipment = 'Equipment',
	PerformanceReview = 'Performance review',
	Teamboard = 'Teamboard',
	AdminPanelSettings = 'Settings',
	AdminPanelSkills = 'Skills',
	UpdateProfile = 'Profile settings',
}
