import { LogoIcon } from '@mms/mms-ui-library';

import { LogoWrapper, SubTitle, Title, TitleWrapper } from './styles';

export function Logo() {
	return (
		<LogoWrapper>
			<TitleWrapper>
				<Title>Modsen</Title>
				<LogoIcon fontSize="small" />
			</TitleWrapper>
			<SubTitle>Management System</SubTitle>
		</LogoWrapper>
	);
}
