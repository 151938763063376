import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography } from '@mms/mms-ui-library';

import { tooltipSizes } from '@/constants/index';

export const DialogTypography = styled(Typography)({
	maxWidth: '288px',
	overflowWrap: 'break-word',
});

export const ConfirmationTypography = styled(Typography)(
	({ theme }) => css`
		margin-top: ${theme.spaces.m}px;
	`
);

export const TooltipTextWrapper = styled('div')(
	() => css`
		display: inline-block;
		white-space: pre-wrap;
		word-wrap: break-word;
		max-width: ${tooltipSizes.MAX_WIDTH}px;
		width: fit-content;
	`
);
