import { PdfIcon, WordIcon, XlsxIcon } from '@mms/mms-ui-library';

import { DocumentFileType } from '@/types/Notifications';

export const getFileTypeIcon = (fileType: DocumentFileType) => {
	switch (fileType) {
		case DocumentFileType.Excel:
			return XlsxIcon;
		case DocumentFileType.Word:
			return WordIcon;
		case DocumentFileType.Pdf:
			return PdfIcon;
		default:
			return XlsxIcon;
	}
};
