import { ThemeMode } from '@mms/mms-ui-library';
import { useTheme } from '@mui/material';
import { useLayoutEffect } from 'react';

export const useBodyBackgroundColor = () => {
	const theme = useTheme();

	useLayoutEffect(() => {
		document.body.setAttribute(
			'style',
			`background-color: ${
				theme.mode === ThemeMode.DARK
					? theme.palette['primary-11']
					: theme.palette['primary-2']
			}`
		);
	}, [theme]);
};
