import {
	ArrowBackMenuIcon,
	Header,
	LinearLoader,
	MenuIcon,
} from '@mms/mms-ui-library';
import { Suspense, useCallback, useMemo, useRef, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { Logo } from '@/components/Logo';
import { UserMenu } from '@/components/UserMenu';
import { SESSION_EXPIRES } from '@/constants/authConstants';
import {
	LocationStates,
	locationStatusKey,
	Routes,
	zIndexes,
} from '@/constants/index';
import { useAuth } from '@/context/AuthContext';
import {
	AppWrapper,
	HeaderIconWrapper,
	LoaderWrapper,
	MainContent,
	CustomScroll,
} from '@/layouts/main/styles';

import { bothScrollLayoutRoutes, HEADER_HEIGHT } from './constants';
import { LeftMenu } from './LeftMenu';
import { Notifications } from './Notifications';
import { LayoutProps } from './types';

const HEADER_STYLES = { height: HEADER_HEIGHT, zIndex: zIndexes.header };

export function Layout({
	withPaddings = false,
	withScroll = false,
	withAfter = true,
}: LayoutProps) {
	const { location } = useAuth();

	const [open, setOpen] = useState(false);

	const burgerButtonRef = useRef<HTMLDivElement>(null);

	const { pathname } = useLocation();

	const handleDrawerOpen = useCallback(() => {
		setOpen((prev) => !prev);
	}, []);

	const exclusionClickAwayElements = useMemo(
		() => (burgerButtonRef.current ? [burgerButtonRef.current] : []),
		[burgerButtonRef.current]
	);

	if (!location) {
		sessionStorage.setItem(locationStatusKey, LocationStates.PartiallyLogged);

		return <Navigate to={Routes.setProfile} />;
	}

	return (
		<AppWrapper>
			<>
				<Header
					isFixed
					logo={<Logo />}
					style={HEADER_STYLES}
					timestamp={Number(sessionStorage.getItem(SESSION_EXPIRES))}
					navigation={
						<HeaderIconWrapper
							id="burger"
							ref={burgerButtonRef}
							aria-label="open drawer"
							onClick={handleDrawerOpen}
						>
							{open ? (
								<ArrowBackMenuIcon />
							) : (
								<MenuIcon width={24} height={24} />
							)}
						</HeaderIconWrapper>
					}
					userMenu={<UserMenu />}
					notificationButton={<Notifications />}
				/>
				<LeftMenu
					exclusionClickAwayElements={exclusionClickAwayElements}
					setIsOpen={setOpen}
					isOpen={open}
				/>
			</>
			<Suspense
				fallback={
					<LoaderWrapper>
						<LinearLoader />
					</LoaderWrapper>
				}
			>
				{withScroll ? (
					<CustomScroll
						maxHeight="100%"
						maxWidth="100%"
						scroll={
							bothScrollLayoutRoutes.includes(pathname)
								? ('both' as 'horizontal')
								: 'horizontal'
						}
					>
						<MainContent withPaddings={withPaddings} withAfter={withAfter}>
							<Outlet />
						</MainContent>
					</CustomScroll>
				) : (
					<MainContent withPaddings={withPaddings} withAfter={withAfter}>
						<Outlet />
					</MainContent>
				)}
			</Suspense>
		</AppWrapper>
	);
}
