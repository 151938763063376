import {
	LightModeIcon,
	NightModeIcon,
	ProfileIcon,
	ServiceDeskIcon,
	SignOutIcon,
} from '@mms/mms-ui-library';

export const menuItemsProps = {
	profile: {
		title: 'Profile',
		icon: ProfileIcon,
	},
	lightTheme: {
		title: 'Light theme',
		icon: LightModeIcon,
	},
	darkTheme: {
		title: 'Dark theme',
		icon: NightModeIcon,
	},
	serviceDesk: {
		title: 'Service desk',
		icon: ServiceDeskIcon,
	},
	logOut: {
		title: 'Log out',
		icon: SignOutIcon,
	},
};
