/* eslint-disable no-nested-ternary */
import moment, { Moment } from 'moment';

import {
	SELECT_DATE,
	UNBLOCK_DIALOG_HEADER,
	CANCEL_DIALOG_HEADER,
	BookPlaceType,
	BookType,
	SURE_TO_UNBLOCK_DESK,
	SURE_TO_CANCEL_BOOKING,
	SELECT_DESK,
	bookingTitleAbriviations,
	SELECT_ROOM,
} from '@/constants/index';
import { SelectedBookingItem } from '@/context/types';
import {
	BOOKING_ITEM_TIME_FORMAT,
	BOOKING_ITEM_TIME_FORMAT_WITH_COMMA,
} from '@/pages/Booking/components/BookingList/constants';
import { LocationTypeId, OfficeType } from '@/types/Configuration';
import { WorkplaceBookingCancelledNotification } from '@/types/Notifications';

const { Repetitive, Multiple, Single } = BookType;
const { Blocked, Selected, Booked, YourBlocked } = BookPlaceType;

export const getBookType = (
	weeksInInterval: number,
	dateList: Array<number | string>
) => (weeksInInterval ? Repetitive : dateList.length > 1 ? Multiple : Single);

export const getBookingPlaceType = (
	currentUserId: number,
	bookUserId: number,
	isBlock: boolean
) => {
	if (isBlock && currentUserId === bookUserId) {
		return YourBlocked;
	}

	if (isBlock) {
		return Blocked;
	}

	if (currentUserId === bookUserId) {
		return Selected;
	}

	return Booked;
};

export const getTextFromMultipleDate = (
	dateList: Array<Moment | number | string>,
	isBlock = false,
	isUtc = false
) => {
	const [day1, month1, year1] = moment(dateList[0])
		.utc(isUtc)
		.format(BOOKING_ITEM_TIME_FORMAT)
		.split(' ');
	const [day2, month2, year2] = moment(dateList.at(-1))
		.utc(isUtc)
		.format(BOOKING_ITEM_TIME_FORMAT)
		.split(' ');

	if (isBlock) {
		return `${day1} ${month1}, ${year1}`;
	}

	if (year1 !== year2) {
		return `${day1} ${month1}, ${year1} - ${day2} ${month2}, ${year2}`;
	}

	if (month1 !== month2) {
		return `${day1} ${month1} - ${day2} ${month2}, ${year1}`;
	}

	if (day1 === day2 && month1 === month2 && year1 === year2) {
		return `${day1} ${month1}, ${year1}`;
	}

	return `${day1}-${day2} ${month1}, ${year1}`;
};

export const createPeriodInText = (
	weeksInInterval: number,
	dateList: Array<number | string>,
	isBlock?: boolean,
	isUtc = false
) => {
	const date = moment(dateList[0]).utc(isUtc);
	switch (getBookType(weeksInInterval, dateList)) {
		case Single:
			return date.format(BOOKING_ITEM_TIME_FORMAT_WITH_COMMA);
		case Multiple:
			return getTextFromMultipleDate(dateList, isBlock, isUtc);
		case Repetitive:
			return `${getTextFromMultipleDate(
				dateList,
				false,
				isUtc
			)} on ${date.format('dddd')}s`;
		default:
			return null;
	}
};

export function getStartOfMonthForRequest(date: Moment | null) {
	return date?.clone().startOf('month').toISOString(true);
}

export function getEndOfMonthForRequest(date: Moment | null) {
	return date
		?.clone()
		.endOf('month')
		.hours(0)
		.minutes(0)
		.seconds(0)
		.milliseconds(0)
		.toISOString(true);
}

export const getDateAsResponseType = (date: Moment) =>
	date.clone().startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');

export const getDateAsResponseTypeWithoutUTC = (date: Moment) =>
	date.clone().startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSS');

export const getTimeAsResponseType = (date: Moment) =>
	date.clone().format('HH:mm:00');

export const getDeleteDialogHeader = (isBlock: boolean) =>
	isBlock ? UNBLOCK_DIALOG_HEADER : CANCEL_DIALOG_HEADER;

export const getDeleteDialogText = (
	{ isBlock, userId }: { isBlock: boolean; userId: number },
	currentUserId: number
) => {
	if (isBlock) {
		return SURE_TO_UNBLOCK_DESK;
	}

	return userId === currentUserId ? SURE_TO_CANCEL_BOOKING : null;
};

export const getDeskBlockDialogSubtext = (firstDay: Moment) => {
	const [day, month, year] = moment(firstDay)
		.format(BOOKING_ITEM_TIME_FORMAT)
		.split(' ');

	return `From ${day} ${month}, ${year}`;
};

export const getTooltipTitle = (
	lastDay: Moment | undefined,
	selected: SelectedBookingItem | null,
	areMeetingRoomsOpen: boolean
) => {
	if (!lastDay) {
		return SELECT_DATE;
	}

	if (areMeetingRoomsOpen && !selected) {
		return SELECT_ROOM;
	}

	if (!areMeetingRoomsOpen && !selected) {
		return SELECT_DESK;
	}

	return null;
};

export const getBookingNotificationDeskTitleAbriviaton = (
	notification: WorkplaceBookingCancelledNotification,
	availableOffices: OfficeType[]
) => {
	const defaultAbriviation = bookingTitleAbriviations[LocationTypeId.Minsk];

	if (!availableOffices) {
		return defaultAbriviation;
	}

	const notificationOffice = availableOffices.find(
		(office) => notification.WorkplaceOfficeId === office.id
	);

	if (notificationOffice) {
		return bookingTitleAbriviations[notificationOffice.location];
	}

	return defaultAbriviation;
};
