import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { lightPalette } from '@mms/mms-ui-library';

import { TabableContainer } from '@/components/TabableContainer';

import {
	NOTIFICATION_BADGE_LEFT,
	NOTIFICATION_BADGE_SIZE,
	NOTIFICATION_BADGE_TOP,
} from './constants';

export const NotificationButtonWrapper = styled(TabableContainer)(
	() => css`
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		cursor: pointer;
		color: ${lightPalette['primary-1']};
	`
);

export const Badge = styled('div')<{ showBadge: boolean }>(
	({ theme, showBadge }) => css`
		border-radius: 50%;
		height: ${NOTIFICATION_BADGE_SIZE}px;
		width: ${NOTIFICATION_BADGE_SIZE}px;
		border: 1px solid ${theme.palette['exc-popups-primary-10']};
		position: absolute;
		background: ${theme.palette['accent-6']};
		top: ${NOTIFICATION_BADGE_TOP}px;
		left: ${NOTIFICATION_BADGE_LEFT}px;
		transition: transform 0.4s;
		transform: scale(${showBadge ? 1 : 0});
	`
);
