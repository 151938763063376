import { Menu } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ThemeMode } from '@/theme';

export const UserMenuWrapper = styled('div')({
	display: 'flex',
	alignItems: 'center',
	textAlign: 'center',
});

export const CustomUserMenu = styled(Menu)(({ theme }) => ({
	'& .MuiPaper-root': {
		boxShadow: theme.customShadows[3],
		borderRadius: '6px',
		background:
			theme.palette.mode === ThemeMode.LIGHT
				? theme.palette['primary-1']
				: theme.palette['primary-11'],
	},
}));
