import { Bell } from '@mms/mms-ui-library';
import { forwardRef } from 'react';

import { HeaderIconWrapper } from '../../styles';

import { Badge, NotificationButtonWrapper } from './styles';
import { NotificationButtonProps } from './types';

export const NotificationsButton = forwardRef<
	HTMLButtonElement,
	NotificationButtonProps
>(({ onClick, showBadge }, ref) => (
	<NotificationButtonWrapper ref={ref} onClick={onClick}>
		<HeaderIconWrapper>
			<Bell />
		</HeaderIconWrapper>
		<Badge showBadge={showBadge} />
	</NotificationButtonWrapper>
));
