import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeMode } from '@mms/mms-ui-library';

const LOGO_SIZE = {
	laptop: {
		width: 336,
		height: 76,
	},
	mobile: {
		width: 264,
		height: 59,
	},
};

const LOGO_TITLE = {
	laptop: {
		fontSize: 28,
		lineHeight: 36,
		fontWeight: 800,
	},
	mobile: {
		fontSize: 22,
	},
};

export const Image = styled('img')(({ theme }) => ({
	...LOGO_SIZE.laptop,

	[theme.breakpoints.down(theme.breakpoints.desktopXS)]: {
		...LOGO_SIZE.mobile,
	},
}));

export const Title = styled('h1')(
	({ theme }) => css`
		text-transform: uppercase;
		text-align: center;
		margin: ${theme.spaces.l}px 0 ${theme.spaces.xl}px 0;
		color: ${theme.mode === ThemeMode.DARK
			? theme.palette['base-6']
			: theme.palette['primary-10']};
		font-weight: ${LOGO_TITLE.laptop.fontWeight};
		font-size: ${LOGO_TITLE.laptop.fontSize}px;
		line-height: ${LOGO_TITLE.laptop.lineHeight}px;
		font-family: ${theme.typography.fontFamily};

		${theme.breakpoints.down(theme.breakpoints.desktopXS)} {
			font-size: ${LOGO_TITLE.mobile.fontSize}px;
			margin: ${theme.spaces.m}px 0 ${theme.spaces.xl}px 0;
		}
	`
);
