import { useTheme } from '@emotion/react';
import { Typography } from '@mms/mms-ui-library';

import { CustomMenuItem } from './styles';
import { MenuItemProps } from './types';

export function MenuItem({ title, icon: Icon, onClick }: MenuItemProps) {
	const theme = useTheme();

	return (
		<CustomMenuItem onClick={onClick}>
			<Typography variant="m-400" color={theme.palette['base-6']}>
				{title}
			</Typography>
			<Icon />
		</CustomMenuItem>
	);
}
