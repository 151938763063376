import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import {
	getUserNotificationsPreviewEndpointUrl,
	NotificationQueriesKeys,
} from '@/constants/index';
import { NotificationType } from '@/types/Notifications';

export const useGetUserNotificationsPreview = (
	userId: number,
	includedNotificationTypes?: Array<NotificationType>
): UseQueryResult<boolean, AxiosError> =>
	useQuery<boolean, AxiosError>({
		queryKey: [
			NotificationQueriesKeys.notificationPreview,
			userId,
			includedNotificationTypes,
		],
		queryFn: () =>
			axios
				.get<boolean>(`${getUserNotificationsPreviewEndpointUrl(userId)}`, {
					params: {
						notificationTypes: includedNotificationTypes,
					},
					paramsSerializer: {
						indexes: null,
					},
				})
				.then((respone) => respone.data)
				.catch((error) => error),
		refetchOnWindowFocus: false,
		keepPreviousData: false,
	});
