import styled from '@emotion/styled';
import { ThemeMode } from '@mms/mms-ui-library';

import { SMALL_ICON_SIZE } from '@/constants/commonStyles';

export const CustomMenuItem = styled('li')(({ theme }) => ({
	padding: theme.spaces.s,
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	color: theme.palette['base-6'],
	cursor: 'pointer',

	'&:hover': {
		background:
			theme.mode === ThemeMode.DARK
				? theme.palette['primary-4']
				: theme.palette['base-1'],
	},

	svg: {
		height: SMALL_ICON_SIZE,
		width: SMALL_ICON_SIZE,
	},
}));
