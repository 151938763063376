import { LocationTypeId } from '@/types/Configuration';

export interface OfficeSelectorOption {
	name: string;
	officeLocation: LocationTypeId;
}

export enum BookingTabsIndexes {
	BookingForm = 0,
	Workplace = 1,
}

export enum BookingOfficesId {
	Minsk = 1,
	Warsaw = 3,
}

export enum MinskSpacesInternalCodes {
	SpaceA = 'MPN186-S403',
	SpaceB = 'MPN186-S404',
	SpaceC = 'MPN186-S405',
	SpaceD = 'MPN186-S406',
}
