import moment from 'moment';

import { FULL_DAY_PERIOD_TEXT } from '@/components/Timeline/BookedLabel/constants';
import {
	RESPONSE_TIME_FORMAT,
	VIEW_TIME_FORMAT,
	RESPONSE_ALL_DAY_START_TIME_FORMAT,
	RESPONSE_ALL_DAY_END_TIME_FORMAT,
	UTC_RESPONSE_TIME_FORMAT,
	ALL_DAY_TIME_HH_MM,
} from '@/constants/booking';

import { calculateTimeDifferenceInMinutes } from './bookingTime';
import { minsToHrsAndMinsObject } from './translateTimeUtils';

export const getFormattedTime = (
	hours: string | number,
	minutes: string | number
) => `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

export const getFormattedTimePeriod = (
	fromTime: string,
	toTime: string,
	calculateHours: boolean
) => {
	const fromTimeDate = moment(fromTime);
	const toTimeDate = moment(toTime);

	const isToTimeEndOfDay =
		toTimeDate.format(RESPONSE_TIME_FORMAT) ===
		RESPONSE_ALL_DAY_END_TIME_FORMAT;

	if (
		fromTimeDate.format(RESPONSE_TIME_FORMAT) ===
			RESPONSE_ALL_DAY_START_TIME_FORMAT &&
		isToTimeEndOfDay
	) {
		return FULL_DAY_PERIOD_TEXT;
	}

	if (isToTimeEndOfDay) {
		toTimeDate.add(1, 'seconds');
	}

	if (!calculateHours) {
		return `${fromTimeDate.format(VIEW_TIME_FORMAT)} - ${toTimeDate.format(
			VIEW_TIME_FORMAT
		)}`;
	}

	const { hours: differenceHours, minutes: differenceMinutes } =
		minsToHrsAndMinsObject(
			calculateTimeDifferenceInMinutes(fromTimeDate, toTimeDate)
		);

	const differenceMinutesString = differenceMinutes
		? `${differenceMinutes} min`
		: '';
	const differenceHoursString = differenceHours ? `${differenceHours} h` : '';
	const hasSpace = differenceHoursString && differenceMinutesString;

	return `${fromTimeDate.format(VIEW_TIME_FORMAT)} - ${toTimeDate.format(
		VIEW_TIME_FORMAT
	)} (${differenceHoursString}${
		hasSpace ? ' ' : ''
	}${differenceMinutesString})`;
};

export const getFormattedDateTimePeriodWithUTC = (
	fromTime: string,
	toTime: string
) => {
	const formattedFrom = moment(fromTime).format(VIEW_TIME_FORMAT);
	const formattedTo = moment(toTime).format(VIEW_TIME_FORMAT);

	if (
		formattedFrom === ALL_DAY_TIME_HH_MM.from &&
		formattedTo === ALL_DAY_TIME_HH_MM.to
	) {
		return FULL_DAY_PERIOD_TEXT;
	}

	const fromTimeDate = moment(fromTime);
	let toTimeDate = moment(toTime);

	if (
		toTimeDate.format(UTC_RESPONSE_TIME_FORMAT) ===
		RESPONSE_ALL_DAY_END_TIME_FORMAT
	) {
		toTimeDate = toTimeDate.add(1, 'second');
	}

	return `${fromTimeDate.format(VIEW_TIME_FORMAT)} - ${toTimeDate.format(
		VIEW_TIME_FORMAT
	)}`;
};
