import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

import { ServerResponseError } from '../types';

import { getUserNotificationPrefixEndpointUrl } from '@/constants/index';
import { NotificationType } from '@/types/Notifications';

import { DeleteAllNotificationResponse } from './types';

export const useDeleteAllNotification = (
	includedNotificationTypes: Array<NotificationType>,
	options?: UseMutationOptions<
		DeleteAllNotificationResponse,
		AxiosError<ServerResponseError>,
		number
	>
) =>
	useMutation<
		DeleteAllNotificationResponse,
		AxiosError<ServerResponseError>,
		number
	>({
		mutationFn: async (userId: number) => {
			const { data } = await axios.delete<DeleteAllNotificationResponse>(
				`${getUserNotificationPrefixEndpointUrl(userId)}`,
				{
					params: {
						notificationTypes: includedNotificationTypes,
					},
					paramsSerializer: {
						indexes: null,
					},
				}
			);

			return data;
		},
		...options,
	});
