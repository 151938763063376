import { SelectionModalOption } from '@/components/Grid/renderers/header/types';

import { BillabilityStatus } from './billabilityStatus';

export const ActivityStatus = {
	Active: 'Active',
	Inactive: 'Inactive',
};

export const activityLogicalOptions = [
	ActivityStatus.Active,
	ActivityStatus.Inactive,
] as [string, string];

export const billabilityLogicalOptions = [
	BillabilityStatus.Billable,
	BillabilityStatus.NonBillable,
] as [string, string];

export const ACTIVATE = 'Activate';
export const DEACTIVATE = 'Deactivate';

export const getDeactivatingPopUpTitle = (entityName: string) =>
	`${entityName} deactivating`;

export const getActivatingPopUpTitle = (entityName: string) =>
	`${entityName} activating`;

export const getAddingPopUpTitle = (entityName: string) =>
	`${entityName} adding`;

export const getEditingPopUpTitle = (entityName: string) =>
	`${entityName} editing`;

export const getEditingDeactivatedEntityMessage = (entityName: string) =>
	`You can't edit deactivated ${entityName}.`;

export const getDeactivatingConfirmationMessage = (entityName: string) =>
	`${entityName} will be deactivated. Are you sure?`;

export const getActivatingConfirmationMessage = (entityName: string) =>
	`${entityName} will be activated. Are you sure?`;

export const statusOptions: Array<SelectionModalOption> = [
	{
		id: ActivityStatus.Active,
		description: ActivityStatus.Active,
		disabled: false,
	},
	{
		id: ActivityStatus.Inactive,
		description: ActivityStatus.Inactive,
		disabled: false,
	},
];
