/* eslint-disable import/no-extraneous-dependencies */
import { HubConnection } from '@microsoft/signalr';
import { Loader, Typography } from '@mms/mms-ui-library';
import React, {
	MouseEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { HEADER_INCLUDED_NOTIFCATION_TYPES } from '../constants';
import { parseRawNotification } from '../utils';

import { SortDirection } from '@/constants/sort';
import { useAuth } from '@/context/AuthContext';
import { useScrollToBottom } from '@/hooks/useScrollToBottom';
import { GetNotificationsParams } from '@/queries/notifications/types';
import { useDeleteAllNotification } from '@/queries/notifications/useDeleteAllNotification';
import { useDeleteNotification } from '@/queries/notifications/useDeleteNotification';
import { useGetUserNotifications } from '@/queries/notifications/useGetNotifications';
import { useReadNotifications } from '@/queries/notifications/useReadNotifications';
import {
	NotificationType,
	RawUserNotification,
	UserNotification,
} from '@/types/Notifications';

import { ApproveClearAllDialog } from './ApproveClearAllDialog';
import {
	NOTIFICATION_LIST_PARENT_CLASS,
	notificationsHubEvents,
} from './constants';
import { NotificationsParameteresContext } from './context/NotificationsContext';
import { NoNotificationsPlaceholder } from './NoNotificationsPlaceholder';
import { NotificationListItem } from './NotificationListItem';
import {
	ClearAllButton,
	NotificationsListHeaderWrapper,
	ScrollbarWrapper,
	NotificationsListHeaderSpaceContainer,
	NotificationsListBodySpaceContainer,
	LoaderContainer,
	AllClearDialogWrapper,
	NotificationListWrapper,
	HeaderTypography,
} from './styles';
import { NotificationParameteresContextValue } from './types';
import { isNotificationReadable, parseNotificationResponse } from './utils';

const NOTIFICATIONS_PER_PAGE = 10;

interface NotificationListProps {
	connection: HubConnection | null;
	refetchUnreadNotificationStatus: () => void;
	hasUnreadNotifications: boolean;
	maxHeight: number;
}

function NotificationListInnner({
	connection,
	refetchUnreadNotificationStatus,
	hasUnreadNotifications,
	maxHeight,
}: NotificationListProps) {
	const { id: userId } = useAuth();
	const listRef = useRef<HTMLDivElement | null>(null);
	const prevFetchDateRef = useRef<number | undefined>(undefined);
	const [notifications, setNotifications] = useState<Array<UserNotification>>(
		[]
	);
	const [isClearAllDialogOpen, setIsClearAllDialogOpen] = useState(false);
	const highestNotificationIdRef = useRef(0);

	const [queryParams, setQueryParams] = useState<GetNotificationsParams>({
		userId,
		skip: notifications.length,
		top: NOTIFICATIONS_PER_PAGE,
		orderBy: `id ${SortDirection.DESC}`,
		filter: {
			isDeleted: false,
			not: {
				notificationType: {
					in: [
						NotificationType.WorkplaceBookingCancelled,
						NotificationType.MeetingRoomBookingCancelled,
					],
				},
			},
		},
	});

	const { data, dataUpdatedAt, isLoading } =
		useGetUserNotifications(queryParams);

	const hasNextNotifications = useMemo(
		() => !data || data.total > notifications.length,
		[data, notifications.length]
	);

	const { mutateAsync: readNotificationsMutationAsync } =
		useReadNotifications();

	useEffect(() => {
		if (data && prevFetchDateRef.current !== dataUpdatedAt) {
			prevFetchDateRef.current = dataUpdatedAt;

			const newNotifications = parseNotificationResponse(data.items);

			if (newNotifications.length) {
				const topNotification = newNotifications.at(0)!;

				if (highestNotificationIdRef.current < topNotification.id) {
					highestNotificationIdRef.current = topNotification.id;
				}
			}

			const toReadNotificationIds: number[] = newNotifications
				.filter(isNotificationReadable)
				.map((notification) => notification.id);

			setNotifications((prevItems) => [...prevItems, ...newNotifications]);

			if (toReadNotificationIds.length) {
				readNotificationsMutationAsync(toReadNotificationIds).then(() => {
					refetchUnreadNotificationStatus();
				});
			} else {
				refetchUnreadNotificationStatus();
			}
		}
	}, [data]);

	useEffect(() => {
		if (connection && !isLoading) {
			const handleRecieveNewNotification = (
				rawNotification: RawUserNotification
			) => {
				if (highestNotificationIdRef.current >= rawNotification.id) {
					return;
				}

				const parsedNotification = parseRawNotification(rawNotification);

				setNotifications((prevNotifications) => [
					parsedNotification,
					...prevNotifications,
				]);

				if (isNotificationReadable(parsedNotification)) {
					readNotificationsMutationAsync([parsedNotification.id]).then(() => {
						refetchUnreadNotificationStatus();
					});
				}
			};

			connection.on(
				notificationsHubEvents.receiveNotification,
				handleRecieveNewNotification
			);

			return () => {
				connection.off(
					notificationsHubEvents.receiveNotification,
					handleRecieveNewNotification
				);
			};
		}
	}, [connection, refetchUnreadNotificationStatus, isLoading]);

	const handleNotificationListScrollToBottom = useCallback(() => {
		if (hasNextNotifications) {
			setQueryParams((prevQueryParams) => ({
				...prevQueryParams,
				skip: notifications.length,
			}));
		}
	}, [notifications.length, hasNextNotifications]);

	useScrollToBottom(
		listRef.current,
		handleNotificationListScrollToBottom,
		undefined,
		true
	);

	const { mutateAsync: deleteNotificationAsync } = useDeleteNotification();

	const { mutate: deleteAllNotifications } = useDeleteAllNotification(
		HEADER_INCLUDED_NOTIFCATION_TYPES,
		{
			onSuccess: () => {
				setNotifications([]);
				refetchUnreadNotificationStatus();
			},
		}
	);

	const deleteNotification = useCallback(
		(notificationId: number) => {
			deleteNotificationAsync(notificationId).then(() => {
				setNotifications((prevNotifications) =>
					prevNotifications.filter(({ id }) => notificationId !== id)
				);
				refetchUnreadNotificationStatus();
			});
		},
		[deleteNotificationAsync, refetchUnreadNotificationStatus]
	);

	const readNotification = useCallback((notificationId: number) => {
		readNotificationsMutationAsync([notificationId]).then(() => {
			setNotifications((prevNotifications) =>
				prevNotifications.map((prevNotification) => {
					if (prevNotification.id === notificationId) {
						return {
							...prevNotification,
							read: true,
						};
					}

					return prevNotification;
				})
			);
			refetchUnreadNotificationStatus();
		});
	}, []);

	const notificationParameteresContextValue: NotificationParameteresContextValue =
		useMemo(
			() => ({
				deleteNotification,
				readNotification,
			}),
			[deleteNotification, readNotification]
		);

	const clearAllNotifications = useCallback(() => {
		deleteAllNotifications(userId);
	}, [userId]);

	const openClearAllDialog = useCallback(() => {
		setIsClearAllDialogOpen(true);
	}, []);

	const closeClearAllDialog = useCallback(() => {
		setIsClearAllDialogOpen(false);
	}, []);

	const handleApproveClearAllDialog = useCallback(() => {
		clearAllNotifications();
		closeClearAllDialog();
	}, []);

	const handleClearButtonClick = useCallback(() => {
		if (!notifications.length) {
			return;
		}

		if (hasUnreadNotifications) {
			openClearAllDialog();
		} else {
			clearAllNotifications();
		}
	}, [notifications, hasUnreadNotifications]);

	const handleClearAllDialogMouseDown = useCallback((event: MouseEvent) => {
		event.stopPropagation();
	}, []);

	const showLoader = useMemo(
		() => !notifications.length && isLoading,
		[isLoading, Boolean(notifications.length)]
	);

	return (
		<>
			<NotificationListWrapper maxHeight={maxHeight}>
				<NotificationsListHeaderSpaceContainer>
					<NotificationsListHeaderWrapper>
						<HeaderTypography variant="l-600">Notifications</HeaderTypography>
						{notifications.length > 0 && (
							<ClearAllButton onClick={handleClearButtonClick}>
								<Typography variant="m-400">Clear all</Typography>
							</ClearAllButton>
						)}
					</NotificationsListHeaderWrapper>
				</NotificationsListHeaderSpaceContainer>
				<NotificationsParameteresContext.Provider
					value={notificationParameteresContextValue}
				>
					<ScrollbarWrapper ref={listRef}>
						{showLoader && (
							<LoaderContainer>
								<Loader />
							</LoaderContainer>
						)}
						{!showLoader && Boolean(notifications.length) && (
							<NotificationsListBodySpaceContainer
								className={NOTIFICATION_LIST_PARENT_CLASS}
							>
								{notifications.map((item) => (
									<NotificationListItem key={item.id} item={item} />
								))}
							</NotificationsListBodySpaceContainer>
						)}
						{!showLoader && !notifications.length && (
							<NoNotificationsPlaceholder />
						)}
					</ScrollbarWrapper>
				</NotificationsParameteresContext.Provider>
			</NotificationListWrapper>
			{isClearAllDialogOpen && (
				<AllClearDialogWrapper onMouseDown={handleClearAllDialogMouseDown}>
					<ApproveClearAllDialog
						onClose={closeClearAllDialog}
						onApprove={handleApproveClearAllDialog}
					/>
				</AllClearDialogWrapper>
			)}
		</>
	);
}

export const NotificationList = React.memo(NotificationListInnner);
