import React, { useMemo } from 'react';

import { NotificationType, UserNotification } from '@/types/Notifications';

import { CreatedTimeTrackReportNotification } from './CreatedTimeTrackReportNotification';
import { GeneratingTimeTrackReportNotification } from './GereratingTimeTrackReportNotification';
import { NotificationHeader } from './NotificationHeader';
import { ReportGeneratingFailureNotification } from './ReportGeneratingFailureNotification';
import {
	NotificationListItemContentWrapper,
	NotificationListItemWrapper,
} from './styles';

interface NotificationListItemProps {
	item: UserNotification;
}

function NotificationListItemInner({ item }: NotificationListItemProps) {
	const notificationContent = useMemo(() => {
		switch (item.notificationType) {
			case NotificationType.TimeTrackReportGenerationFailed:
				return <ReportGeneratingFailureNotification />;
			case NotificationType.TimeTrackReportCreated:
				return <CreatedTimeTrackReportNotification item={item} />;
			case NotificationType.TimeTrackReportGenerating:
				return <GeneratingTimeTrackReportNotification />;
			default:
				return null;
		}
	}, [item]);

	return (
		<NotificationListItemWrapper>
			<NotificationHeader id={item.id} isRead={item.read} />
			<NotificationListItemContentWrapper isRead={item.read}>
				{notificationContent}
			</NotificationListItemContentWrapper>
		</NotificationListItemWrapper>
	);
}

export const NotificationListItem = React.memo(NotificationListItemInner);
