import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import {
	getReportFileEndpointUrl,
	NotificationQueriesKeys,
} from '@/constants/index';

export const useGetReportFile = (
	fileId: string,
	notificationId: number,
	options?: UseQueryOptions<Blob, AxiosError>
): UseQueryResult<Blob, AxiosError> =>
	useQuery<Blob, AxiosError>({
		queryKey: [NotificationQueriesKeys.reportFile, fileId, notificationId],
		queryFn: () =>
			axios
				.get<Blob>(`${getReportFileEndpointUrl(fileId)}`, {
					responseType: 'blob',
					paramsSerializer: {
						indexes: true,
					},
				})
				.then((respone) => respone.data)
				.catch((error) => {
					throw error;
				}),
		refetchOnWindowFocus: false,
		keepPreviousData: false,
		retry: false,
		...options,
	});
