import { useTheme } from '@emotion/react';
import { PopUp } from '@mms/mms-ui-library';

import {
	ConfirmationTypography,
	DialogTypography,
} from '@/components/CustomTypographies';

import {
	CLEAR_ALL_DIOLOG_BODY,
	CLEAR_ALL_DIOLOG_HEADER,
	CLEAR_ALL_DIOLOG_TITLE,
} from './constants';

interface ApproveClearAllDialogProps {
	onClose: () => void;
	onApprove: () => void;
}

export function ApproveClearAllDialog({
	onClose,
	onApprove,
}: ApproveClearAllDialogProps) {
	const theme = useTheme();

	return (
		<PopUp
			title={CLEAR_ALL_DIOLOG_TITLE}
			onClose={onClose}
			type="approve"
			showCloseButton={false}
			controls={{
				positiveControl: { onClick: onApprove },
				negativeControl: { onClick: onClose },
			}}
			zIndex={1501}
			stylesConfig={{
				container: {
					backgroundColor: theme.palette['primary-2'],
				},
			}}
		>
			<DialogTypography variant="m-400">
				{CLEAR_ALL_DIOLOG_HEADER}
			</DialogTypography>
			<ConfirmationTypography variant="m-400">
				{CLEAR_ALL_DIOLOG_BODY}
			</ConfirmationTypography>
		</PopUp>
	);
}
