import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { NOTIFICATION_OPACITY } from '../constants';

export const NotificationListItemWrapper = styled('div')(
	({ theme }) => css`
		padding-bottom: ${theme.spaces.m}px;
		border-bottom: 1px solid ${theme.palette['primary-4']};
		display: flex;
		flex-direction: column;
	`
);

export const NotificationListItemContentWrapper = styled('div')<{
	isRead: boolean;
}>(
	({ isRead }) => css`
		opacity: ${isRead ? NOTIFICATION_OPACITY : 1};
	`
);
