import { CrossIcon } from '@mms/mms-ui-library';
import { useCallback, useContext } from 'react';

import { NotificationsParameteresContext } from '../../context/NotificationsContext';

import { HoverCircle } from '@/components/HoverCircle';

import {
	DeleteButtonWrapper,
	NotificationHeaderTypography,
	NotificationHeaderWrapper,
} from './styles';
import { NotificationProps } from './types';

export function NotificationHeader({
	title = 'Modsen App',
	id,
	isRead,
}: NotificationProps) {
	const { deleteNotification } = useContext(NotificationsParameteresContext);

	const handleCrossClick = useCallback(() => {
		deleteNotification(id);
	}, []);

	return (
		<NotificationHeaderWrapper>
			<NotificationHeaderTypography isRead={isRead} variant="m-600">
				{title}
			</NotificationHeaderTypography>
			<DeleteButtonWrapper onClick={handleCrossClick}>
				<HoverCircle lightThemeColor="base-1" size={24}>
					<CrossIcon />
				</HoverCircle>
			</DeleteButtonWrapper>
		</NotificationHeaderWrapper>
	);
}
