import axios, { AxiosError, isAxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import {
	getMeetingRoomsCollisionsApiUrl,
	BookingQueriesKeys,
} from '@/constants/index';

import { GetMeetingRoomsCollisions } from './types';

interface UseCheckMeetingRoomsCollisionsProps {
	meetingRoomId?: number;
	ReservationFromUtc?: string;
	ReservationToUtc?: string;
	ReservationPeriodFromOfficeDate?: string;
	ReservationPeriodToOfficeDate?: string;
	enabled: boolean;
	UserId?: number;
	RepeatReservationWeeks?: number | null;
}

export const useCheckMeetingRoomsCollisions = (
	{
		ReservationFromUtc,
		ReservationToUtc,
		ReservationPeriodFromOfficeDate,
		ReservationPeriodToOfficeDate,
		enabled,
		UserId,
		RepeatReservationWeeks,
		meetingRoomId,
	}: UseCheckMeetingRoomsCollisionsProps,
	options?: UseQueryOptions<GetMeetingRoomsCollisions, AxiosError>
) =>
	useQuery<GetMeetingRoomsCollisions, AxiosError>({
		queryKey: [
			BookingQueriesKeys.meetingRoomCollisions,
			ReservationFromUtc,
			ReservationToUtc,
			ReservationPeriodFromOfficeDate,
			ReservationPeriodToOfficeDate,
			enabled,
			UserId,
			RepeatReservationWeeks,
			meetingRoomId,
		],
		queryFn: async () => {
			if (meetingRoomId) {
				try {
					const { data } = await axios.get<GetMeetingRoomsCollisions>(
						getMeetingRoomsCollisionsApiUrl(meetingRoomId),
						{
							params: {
								ReservationFromUtc,
								ReservationToUtc,
								ReservationPeriodFromOfficeDate,
								ReservationPeriodToOfficeDate,
								UserId,
								RepeatReservationWeeks,
							},
						}
					);

					return data;
				} catch (error) {
					if (isAxiosError(error)) {
						throw error;
					}
				}
			}

			return [];
		},
		keepPreviousData: false,
		enabled: enabled && Boolean(meetingRoomId),
		refetchOnWindowFocus: false,
		...options,
	});
