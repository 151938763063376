import { useTheme } from '@emotion/react';
import { LogOutIcon, PopUp } from '@mms/mms-ui-library';
import { useEffect, useState } from 'react';

import { DialogTypography } from '../DialogTypography/styles';

import { zIndexes } from '@/constants/index';

import {
	INTERVAL_DURATION,
	LOG_OUT_MESSAGE,
	LOG_OUT_MESSAGE_TIME,
	SECONDS_BEFORE_LOG_OUT,
} from './constants';
import { FixedTypography } from './styles';
import { LogOutPopUpProps } from './types';
import { getFormattedSeconds } from './utils';

export function LogOutPopUp({
	handleClose,
	handleLogOut,
	handleStayLogIn,
}: LogOutPopUpProps) {
	const [secondsToLogOut, setSecondsToLogOut] = useState(
		SECONDS_BEFORE_LOG_OUT
	);
	const theme = useTheme();

	useEffect(() => {
		const id = setInterval(() => {
			setSecondsToLogOut((prev) => prev - 1);
		}, INTERVAL_DURATION);

		return () => {
			clearInterval(id);
		};
	}, []);

	return (
		<PopUp
			title="INACTIVITY LOG OUT"
			headerIcon={<LogOutIcon />}
			onClose={handleClose}
			type="approve"
			showCloseButton={false}
			controls={{
				positiveControl: {
					onClick: handleStayLogIn,
					text: 'Stay logged in',
					size: 'medium',
				},
				negativeControl: {
					onClick: handleLogOut,
					text: 'Log out',
				},
			}}
			zIndex={zIndexes.logOutPopUp}
			stylesConfig={{
				button: {
					gap: theme.spaces.m,
				},
			}}
		>
			<DialogTypography align="center" variant="m-400">
				{LOG_OUT_MESSAGE}
				<FixedTypography variant="m-600">
					{getFormattedSeconds(secondsToLogOut)}
				</FixedTypography>
				{LOG_OUT_MESSAGE_TIME}
			</DialogTypography>
		</PopUp>
	);
}
