export const time = {
	WORK_WEEK: '40:00',
	WORK_DAY: '08:00',
};

export const MILLISECONDS_IN_SECOND = 1000;

export const MAX_SECONDS_COUNT = 59;

export const FULL_HOURS_IN_DAY = 24;
