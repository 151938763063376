import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { NOTIFICATION_OPACITY } from '../../constants';
import { HeaderTypography } from '../../styles';

import { TabableContainer } from '@/components/TabableContainer';

export const NotificationHeaderWrapper = styled('div')(
	({ theme }) => css`
		display: flex;
		justify-content: space-between;
		margin-bottom: ${theme.spaces.xs}px;
	`
);

export const NotificationHeaderTypography = styled(HeaderTypography)<{
	isRead: boolean;
}>(
	({ isRead }) => css`
		opacity: ${isRead ? NOTIFICATION_OPACITY : 1};
	`
);

export const DeleteButtonWrapper = styled(TabableContainer)(
	({ theme }) => css`
		cursor: pointer;
		color: ${theme.palette['base-4']};

		& svg > path {
			stroke-width: 1.5;
		}
	`
);
