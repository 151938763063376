import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpoints, lightTheme } from '@mms/mms-ui-library';

const mobileBreakpoint = breakpoints.tabletS;
const fontSize = 16;
const fontWeight = 700;

export const LogoWrapper = styled('div')(
	({ theme }) => css`
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 0 ${theme.spaces.s}px;

		${theme.breakpoints.down(mobileBreakpoint)} {
			gap: ${theme.spaces.xs}px;
		}
	`
);

export const Title = styled('h6')(() => ({
	color: lightTheme.palette['primary-1'],
	fontSize,
	fontWeight,
	lineHeight: 'normal',
	display: 'inline-flex',
	margin: 0,
}));

export const SubTitle = styled('div')(() => ({
	color: lightTheme.palette['primary-1'],
	fontSize,
	fontWeight,
	lineHeight: 'normal',
	display: 'inline-flex',
	whiteSpace: 'nowrap',
}));

export const TitleWrapper = styled('div')(
	({ theme }) => css`
		display: contents;

		${theme.breakpoints.down(mobileBreakpoint)} {
			display: flex;
			gap: ${theme.spaces.xs}px;
			align-items: center;
		}
	`
);
