import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { darkTheme, lightTheme, ThemeMode } from '@mms/mms-ui-library';

interface HoverCircleProps {
	lightThemeColor: keyof typeof lightTheme.palette;
	darkThemeColor?: keyof typeof darkTheme.palette;
	size: number;
}

export const HoverCircle = styled('div')<HoverCircleProps>(
	({ theme, lightThemeColor, darkThemeColor, size }) => css`
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;

		&:hover {
			transition: all 0.5s;
			width: ${size}px;
			height: ${size}px;
			background-color: ${theme.palette[
				theme.mode === ThemeMode.DARK
					? darkThemeColor || lightThemeColor
					: lightThemeColor
			]};
		}
	`
);
