import { Roles } from '../constants';

export interface DashboardStatisticsItem {
	title: string;
	subtitle: string;
	extraTitle?: string;
}

export enum TimePeriod {
	Year = 'Year',
	Month = 'Month',
	Quarter = 'Quarter',
}

export enum RangeTimePeriod {
	Month = 1,
	Quarter = 3,
}

export enum NavigationDirection {
	Backward = 'backward',
	Forward = 'forward',
}

export type RolesType = (typeof Roles)[keyof typeof Roles];

export enum TrackedTimeType {
	Fact = 'fact',
	Billable = 'billable',
}
