import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography } from '@mms/mms-ui-library';

export const DialogTypography = styled(Typography)({
	maxWidth: '288px',
	overflowWrap: 'break-word',
});

export const ConfirmationTypography = styled(Typography)(
	({ theme }) => css`
		margin-top: ${theme.spaces.m}px;
	`
);
