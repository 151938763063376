import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { Id, toast } from 'react-toastify';

import { ToastContextValues } from './types';

const ToastContext = createContext({} as ToastContextValues);

export function ToastProvider({ children }: PropsWithChildren) {
	const contextValue = useMemo<ToastContextValues>(
		() => ({
			open: ({ message, settings }) => {
				// eslint-disable-next-line no-unused-vars
				const { type, ...rest } = settings;
				toast(message, {
					...rest,
					style: {
						backgroundColor: 'transparent',
					},
				});
			},
			subscribe: () => {
				const toastes: Id[] = [];
				toast.onChange((payload) => {
					if (payload.status === 'added') {
						toastes.push(payload.id);
					}

					if (payload.status === 'removed') {
						toastes.shift();
					}

					if (toastes.length > 2) {
						toast.dismiss(toastes[0]);
					}
				});
			},
		}),
		[]
	);

	return <ToastContext.Provider value={contextValue} children={children} />;
}

export const useToast = () => useContext(ToastContext);
