import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography } from '@mms/mms-ui-library';

export const FixedTypography = styled(Typography)(
	({ theme }) => css`
		width: ${theme.spaces.xl3}px;
		padding: 0px ${theme.spaces.xs}px;
		display: inline-block;
	`
);
