export const zIndexes = {
	filterCalendarPopup: 1500,
	filterPopup: 1301,
	logOutPopUp: 999999,
	header: 9999,
	sidebar: 102,
	hidden: -1,
	chartLegend: 10,
	formPopup: 1350,
};
