import { MinskSpacesInternalCodes } from '@/pages/Booking/types';

import { WorkSpaceConfigurationGenerator } from './types';
import {
	generateMinskSpaceA,
	generateMinskSpaceB,
	generateMinskSpaceC,
	generateMinskSpaceD,
	generateWarsawSpace,
} from './utils';

export const bookingGridAreas = {
	titleArea: 'title',
	legendArea: 'legend',
	workplaceArea: 'workplace',
};

export const WARSAW_SPACE: WorkSpaceConfigurationGenerator =
	generateWarsawSpace;

type LocationSpaces = {
	[key in MinskSpacesInternalCodes]: WorkSpaceConfigurationGenerator;
};

export const MINSK_SPACES: LocationSpaces = {
	[MinskSpacesInternalCodes.SpaceA]: generateMinskSpaceA,
	[MinskSpacesInternalCodes.SpaceB]: generateMinskSpaceB,
	[MinskSpacesInternalCodes.SpaceC]: generateMinskSpaceC,
	[MinskSpacesInternalCodes.SpaceD]: generateMinskSpaceD,
};

export const MEETING_ROOMS_TAB_ID = 42;
export const BOOKING_DURATION_IN_MONTH = 3;
export const OFFICE_CLOSED_MESSAGE = {
	firstSentence: 'The Minsk office is currently closed.',
	secondSentence: 'We’ll notify you in Teams when booking is available.',
};
