import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import {
	RedirectKeys,
	getRedirectInfoBuIdEndpointUrl,
} from '@/constants/index';
import { RedirectInfo } from '@/types/Redirect';

export const useGetRedirectInfoById = (id: string | undefined) =>
	useQuery<RedirectInfo | undefined, AxiosError>({
		queryKey: [RedirectKeys.getRedirectInfoById, id],
		queryFn: async () => {
			if (id) {
				const res = await axios.get<RedirectInfo>(
					getRedirectInfoBuIdEndpointUrl(id)
				);

				return res.data;
			}

			return undefined;
		},
		refetchOnWindowFocus: false,
		enabled: !!id,
		retry: 0,
	});
