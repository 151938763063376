import { RefObject, useEffect } from 'react';

export const useOutsideClickMany = <T extends HTMLElement>(
	refs: RefObject<T>[],
	action: () => void
) => {
	useEffect(() => {
		const handleClickOutside = (e: MouseEvent) => {
			const portalContainer = document.querySelector('div[portal-container]');

			const isClickedOutside = refs.every(
				(ref) =>
					ref.current &&
					!ref.current.contains(e.target as Node) &&
					!portalContainer?.contains(e.target as Node)
			);

			if (isClickedOutside) {
				action();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [action]);
};
