import { ExportControlOption } from '@/components/ExportControl/types';

export const exportAsConfig: ExportControlOption[] = [
	{
		fileType: 'xlsx',
		name: 'xlsx',
	},
	{
		fileType: 'word',
		name: 'word',
	},
	{
		fileType: 'pdf',
		name: 'pdf',
	},
];
