import { Loader, Typography } from '@mms/mms-ui-library';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { NotFoundPage } from '../NotFound';

import { Hexagons } from '@/components/Hexagons';
import { useGetRedirectInfoById } from '@/queries/useGetRedirectInfoById';

import {
	PageContainer,
	Wrapper,
	ResourceTypography,
	TypographyWrapper,
} from './styles';

export function RedirectPage() {
	const { id } = useParams();

	const { data, error, isFetching } = useGetRedirectInfoById(id);

	useEffect(() => {
		if (data) {
			window.location.href = data.redirectTo;
		}
	}, [data]);

	if (isFetching) {
		return (
			<PageContainer>
				<Hexagons />
				<Wrapper>
					<Loader />
					<Typography variant="m-400">Looking for resource...</Typography>
				</Wrapper>
			</PageContainer>
		);
	}

	if (error || !data) {
		return <NotFoundPage />;
	}

	return (
		<PageContainer>
			<Hexagons />
			<Wrapper>
				<Loader />
				<TypographyWrapper>
					<Typography variant="l-400">
						Redirecting to
						<br />
						<ResourceTypography variant="l-400">
							{data.redirectTo}
						</ResourceTypography>
					</Typography>
				</TypographyWrapper>
			</Wrapper>
		</PageContainer>
	);
}
