import moment, { Moment, MomentObjectOutput } from 'moment';

import { FULL_HOURS_IN_DAY, MAX_SECONDS_COUNT } from '../constants';

import { Timezone } from '@/components/Timeline/types';

export const parseStringToMoment = (timeStr: string) =>
	moment(timeStr, 'HH:mm');

export const parseTimeFromString = (timeStr: string, addOneDay = false) => {
	const [hours, minutes] = timeStr.split(':').map(Number);
	const now = new Date();
	now.setHours(hours, minutes, 0, 0);

	if (addOneDay) {
		now.setDate(now.getDate() + 1);
	}

	return moment(now);
};

export const parseDateToTimeObject = (date: string): MomentObjectOutput => {
	const dateObject = moment(date).toObject();

	if (dateObject.seconds === MAX_SECONDS_COUNT) {
		dateObject.seconds = 0;
		dateObject.minutes = 0;
		dateObject.hours = FULL_HOURS_IN_DAY;
	}

	return dateObject;
};

export const parseMomentToUtcString = (
	date: Moment,
	timezone: Timezone
): string => date.clone().tz(timezone, true).toISOString();
