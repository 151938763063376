import { breakpoints, useHasScreenWidth } from '@mms/mms-ui-library';
import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { getTabIdBySlug, tabsConfig } from '../config';
import { NavigationItemPayload } from '../types';

import { RouteGuard } from '@/components/RouteGuard';
import { SIDEBAR_SELECTED_ITEM_STORAGE_KEY } from '@/constants/storageKeys';
import { zIndexes } from '@/constants/zIndexes';
import { useStickyState } from '@/hooks/useStickyState';

import { SidebarWrapper } from './styles';
import { LeftMenuProps } from './types';

export function LeftMenu({
	isOpen,
	setIsOpen,
	exclusionClickAwayElements,
}: LeftMenuProps) {
	const location = useLocation();

	const [selectedMenuItemId, setSelectedMenuItemId] = useStickyState<
		number | null
	>(tabsConfig[0][0].id, SIDEBAR_SELECTED_ITEM_STORAGE_KEY);

	useEffect(() => {
		const currentId = getTabIdBySlug(location.pathname);

		setSelectedMenuItemId(currentId);
	}, [location.pathname]);

	const handleCloseMenu = useCallback(() => {
		setIsOpen(false);
	}, []);

	const onMenuItemClickHandler = (itemId: number | null) => () => {
		setSelectedMenuItemId(itemId);
		handleCloseMenu();
	};

	const isSideMenuVisible = useHasScreenWidth(breakpoints.desktopXS);

	return (
		<SidebarWrapper
			zIndex={zIndexes.sidebar}
			selectedId={selectedMenuItemId}
			setSelectedId={setSelectedMenuItemId}
			isOpen={isOpen}
			menuItems={tabsConfig}
			small={false}
			exclusionClickAwayElements={exclusionClickAwayElements}
			onClickAway={handleCloseMenu}
			render={({ icon, label, id, payload }) => {
				const itemPayload = payload as NavigationItemPayload;

				return (
					<RouteGuard
						id={id}
						showTooltip={!isOpen && isSideMenuVisible}
						key={id}
						isOpen={isOpen}
						icon={icon}
						label={label}
						onClick={onMenuItemClickHandler(id)}
						selected={selectedMenuItemId === id}
						payload={itemPayload}
					/>
				);
			}}
		/>
	);
}
