import styled from '@emotion/styled';

export const TabableContainer = styled('button')`
	background: none;
	border: none;
	padding: 0;
	color: inherit;
	height: auto;
	font-family: inherit;
`;
