import { SortColumn } from 'react-data-grid';

export const EQUIPMENT_PAGE_VERTICAL_GAPS = 76;

export const INITIAL_ORDER_BY_VALUE = 'id';
export const INITIAL_VALUE: readonly SortColumn[] = [
	{
		columnKey: 'id',
		direction: 'ASC',
	},
];

export const DEFAULT_PURCHASE_DATE_FILTER_VALUE = 'Purchase date';

export const NUMERAL_PRICE_FORMAT = '0,0.00';

export const PRICE_DECIMAL_SCALE = 2;
