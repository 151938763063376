import { Theme } from '@emotion/react';
import styled from '@emotion/styled';

export const CustomHorizontalRow = styled('hr')<{
	color: keyof Theme['palette'];
	margin?: string;
}>(({ theme, color, margin }) => ({
	width: '100%',
	height: 1,
	border: 'none',
	color: theme.palette[color],
	margin: margin || 0,
}));
