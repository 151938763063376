import { MeetingRoomsCollision } from '@/queries/booking/types';

import { createPeriodInText } from './booking';
import { getFormattedDateTimePeriodWithUTC } from './bookingFormatters';

export const getMeetingRoomBookedForPeriod = (
	meetingRoomCollision: MeetingRoomsCollision
) => {
	const {
		reservationFromOfficeDateTime,
		reservationToOfficeDateTime,
		meetingRoomName,
	} = meetingRoomCollision;

	return `You cannot book it, because the ${meetingRoomName} is booked for the following period: ${createPeriodInText(
		0,
		[reservationFromOfficeDateTime]
	)}, ${getFormattedDateTimePeriodWithUTC(
		reservationFromOfficeDateTime,
		reservationToOfficeDateTime
	)}`;
};
