import { useEffect, useState } from 'react';

import { DataTypes } from '@/constants/dataTypes';

export function useStickyState<T = unknown>(
	defaultValue: T,
	key: string,
	dataType?: DataTypes
) {
	const [value, setValue] = useState<T>(() => {
		const stickyValue = sessionStorage.getItem(key);

		if (dataType === DataTypes.Date) {
			return stickyValue !== null
				? new Date(JSON.parse(stickyValue))
				: defaultValue;
		}

		return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
	});

	useEffect(() => {
		if (dataType === DataTypes.Date) {
			sessionStorage.setItem(key, JSON.stringify(Number(value)));
		} else {
			sessionStorage.setItem(key, JSON.stringify(value));
		}
	}, [key, value]);

	return [value, setValue] as const;
}
