import styled from '@emotion/styled';

const SIGN_IN_BTN_WRAPPER_SIZES = {
	laptop: {
		width: 336,
		height: 62,
	},
	mobile: {
		width: 264,
	},
};

export const SignInWrapperBtn = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	position: 'relative',
	zIndex: 9999999,
	...SIGN_IN_BTN_WRAPPER_SIZES.laptop,

	[theme.breakpoints.down(theme.breakpoints.desktopXS)]: {
		...SIGN_IN_BTN_WRAPPER_SIZES.mobile,
	},
}));

export const SignInWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	backgroundColor: theme.palette['primary-1'],
	justifyContent: 'center',
	alignContent: 'center',
	height: '100vh',
	position: 'relative',
	padding: 0,
	overflow: 'hidden',
}));

export const Wrapper = styled('div')({
	display: 'flex',
	maxWidth: 'fit-content',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
});
