import { LOCATION } from '@/constants/location';

export const API_BASE_URL: { [key in LOCATION]: string } = {
	'https://rc.modsen.app': 'https://reports.modsen.app:8080',
	'https://qa.modsen.app': 'https://reports.modsen.app:8182',
	'https://stage.modsen.app': 'https://reports.modsen.app:8181',
	'https://modsen.app': 'https://reports.modsen.app:443',
	'http://localhost:3000': 'https://mms-api-dev.modsen.app',
	'https://mms-fe-dev.modsen.app': 'https://mms-api-dev.modsen.app',
	'https://mms-fe-qa.modsen.app': 'https://mms-api-qa.modsen.app',
	'https://mms-fe-staging.modsen.app': 'https://mms-api-staging.modsen.app',
};
