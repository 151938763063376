import axios, { AxiosError } from 'axios';
import buildQuery from 'odata-query';
import { useQuery } from 'react-query';

import { ByPageResponse, ServerResponseError } from '../types';

import {
	getUserNotificationsEndpointUrl,
	NotificationQueriesKeys,
} from '@/constants/index';
import { RawUserNotification } from '@/types/Notifications';

import { GetNotificationsParams } from './types';

export const useGetUserNotifications = (params: GetNotificationsParams) =>
	useQuery({
		queryKey: [NotificationQueriesKeys.userNotifications, params],
		queryFn: async () => {
			const { userId, ...restParams } = params;
			try {
				const query = buildQuery(restParams);

				const { data } = await axios.get<ByPageResponse<RawUserNotification>>(
					`${getUserNotificationsEndpointUrl(userId)}/${query}`
				);

				return data;
			} catch (error) {
				throw error as AxiosError<ServerResponseError>;
			}
		},
		keepPreviousData: false,
		refetchOnWindowFocus: false,
		cacheTime: 0,
	});
