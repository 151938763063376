import { RawUserNotification, UserNotification } from '@/types/Notifications';

export const parseRawNotification = ({
	outputJson,
	...restProps
}: RawUserNotification): UserNotification => ({
	...restProps,
	...JSON.parse(outputJson),
	readByUser: restProps.read,
});
