export enum BorderRadiuses {
	none = 0,
	xs = 2,
	xs2 = 4,
	s = 3,
	m = 6,
	l = 7,
	xl = 10,
	xl2 = 14,
	xxl = 50,
	half = '50%',
	max = 9999,
}

export const BOOKING_RADIUS = 4;
