// TODO: jsx should'n be in @/helpers
import { TextWithStrongInside } from '@/components/TextWithStrongInside';
import { SURE_TO_CANCEL } from '@/constants/index';
import { TemporaryGetDialogTextComponentParams } from '@/types/helpers';

import { getFullName } from './formatName';

export const getDialogTextComponent = (
	{
		isBlock,
		userId,
		firstName,
		lastName,
	}: TemporaryGetDialogTextComponentParams,
	currentUserId: number
) => {
	if (isBlock) {
		return null;
	}

	return userId !== currentUserId ? (
		<TextWithStrongInside
			before={SURE_TO_CANCEL}
			strong={getFullName(firstName, lastName)}
			after="booking?"
		/>
	) : null;
};
