import axios from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { CONFIGURATION_QUERY_KEY } from '../constants';

import { GET_CONFIGURATION } from '@/constants/queryPaths';
import { Configuration } from '@/types/Configuration';

export const useGetConfiguration = (options: UseQueryOptions<Configuration>) =>
	useQuery<Configuration>({
		queryKey: [CONFIGURATION_QUERY_KEY],
		queryFn: () => axios.get(GET_CONFIGURATION).then((res) => res.data),
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		...options,
	});
