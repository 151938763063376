import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import moment from 'moment';

import { YYYY_MM_DD_MOMENT_DATE_FORMAT } from '../constants';

import { FromToProps } from '@/components/Grid';
import { DateRangeFilter } from '@/components/Grid/modals/types';
import { Booleanify } from '@/types/helpers';

export type Comparator<ValueType> = (a: ValueType, b: ValueType) => boolean;

const areDatesEqual: Comparator<Date | null> = (a, b) =>
	moment(a).format(YYYY_MM_DD_MOMENT_DATE_FORMAT) ===
	moment(b).format(YYYY_MM_DD_MOMENT_DATE_FORMAT);

export const arePrimitiveValuesEqual: Comparator<
	number | string | boolean | null | undefined | symbol | bigint
> = (a, b) => a === b;

export const areArraysEqualByPrimitiveItems: Comparator<(number | string)[]> = (
	a,
	b
) => a?.length === b?.length && a?.every((item) => b.includes(item));

export const areFromToCompatibleObjectsEqual: Comparator<
	FromToProps<Date | string>
> = (a, b) => {
	if (typeof (a.from || a.to || b.from || b.to) === 'object') {
		return (
			areDatesEqual(a.from as Date, b.from as Date) &&
			areDatesEqual(a.to as Date, b.to as Date)
		);
	}

	return a?.from === b?.from && a?.to === b?.to;
};

export const areDateRangesEqual: Comparator<DateRangeFilter> = (a, b) =>
	a.includeNull === b.includeNull &&
	areDatesEqual(a.fromDate, b.fromDate) &&
	areDatesEqual(a.toDate, b.toDate);

export const areObjectsFieldEqual = <T, K extends keyof T>(
	a: T,
	b: T,
	key: K
): boolean => a[key] === b[key];

export const areObjectsShallowlyEqual = <T extends Record<string, any>>(
	obj1: T,
	obj2: T
): Booleanify<T> => mapValues(obj1, (value, key) => isEqual(value, obj2[key]));
