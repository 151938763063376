import { Photo } from '@mms/mms-ui-library';
import { useTheme } from '@mui/material';

import { useGetFile } from '@/queries/useGetFile';
import { ThemeMode } from '@/theme';

import type { AvatarProps } from './types';
import { getBackgroundColor } from './utils';

export function Avatar({
	fileId,
	bgColor,
	isHovered,
	...otherProps
}: AvatarProps) {
	const theme = useTheme();
	const { data, isFetching } = useGetFile(fileId);
	const source = data ? `data:image/jpeg;base64,${data}` : undefined;

	const backgroundColor = getBackgroundColor(theme, bgColor);

	return (
		<Photo
			src={isFetching ? undefined : source}
			color={
				theme.mode === ThemeMode.LIGHT
					? theme.palette['primary-1']
					: theme.palette['primary-8']
			}
			bgColor={isHovered || !source ? backgroundColor : 'transparent'}
			{...otherProps}
		/>
	);
}
